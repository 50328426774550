
export default {

    line: {
        height: 1,
        backgroundColor: '#000',
        width: '97vw',
        marginLeft: '1.5vw',
        marginRight: 0,
        marginBottom: 30,
        marginTop: 0,
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 0,
        paddingLeft: '20vw',
        paddingRight: '20vw'
    },

    section: {
        display: 'flex',
        flexDirection: 'row'
    },

    sectionLeft: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        margin: 0,
        alignItems: 'flex-start'
    },

    sectionRight: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        margin: 0,
        alignItems: 'flex-start',
        zIndex: 5
    },

    row: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 30,
        alignItems: 'flex-start'
    },

    bottomSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        marginTop: -50,
        marginBottom: 20,
        zIndex: 2
    },

    icon: {
        height: 30,
        width: 30
    },

    image: {
        width: 100,
        margin: 0,
        padding: 0,
        objectFit: 'contain',
        cursor: 'pointer'
    },

    image2: {
        width: 150,
        margin: 0,
        padding: 0,
        objectFit: 'contain'
    }

}