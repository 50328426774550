export default {

    container: {
        paddingTop: '10vh',
        paddingBottom: '10vh',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    innerContainer : {
        minHeight : "100vh"
    },
    
    content: {
        width: '60vw',
        paddingBottom: 120,
        paddingLeft: '10vw',
        backgroundColor: 'transparent'
    },
    
    contentMobile: {
        width: '90vw',
        paddingBottom: 60,
    },

}