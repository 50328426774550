
export default {

    container: {
        paddingTop: '10vh',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginLeft: '-10vw'
    },

    innerContainer: {
        minHeight : "80vh",
        width: '80vw',
        marginLeft: '10vw',
        backgroundColor: 'transparent'
    },

    promotionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginLeft: '10vw',
        marginTop: 20
    },

    image: {
        height: 120,
        marginLeft: 20
    },

    verticalSection: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },

    badgeContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '1.5em',
        height: '10em'
    }

}