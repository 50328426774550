export default {

    container: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        backgroundColor: 'transparent',
        right: 0,
        zIndex: 10
    },

    scrollDot: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        cursor:'pointer',
        outline: 'none',
        border: 0,
        backgroundColor: 'transparent'
    },

    scrollDotMobile: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 6,
        paddingBottom: 6,
        cursor:'pointer',
        outline: 'none',
        border: 0,
        backgroundColor: 'transparent'
    }

}