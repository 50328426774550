import React, { useEffect, useState, useContext } from 'react'
import ReactPixel from 'react-facebook-pixel'
import Cookies from 'js-cookie'
import Switch from "react-switch"
// Components.
import BoldText from '../Text/BoldText'
import RegularText from '../Text/RegularText'
import SmallRegularText from '../Text/SmallRegularText'
// Context.
import GeneralContext from '../../Context/generalContext'
// Styling.
import styles from './styles'
import WindowSize from '../General/windowsSize'

function ManageGdprMobile({ setShowCookiesBanner }) {
    const [ marketing, setMarketing ] = useState(false)
    const { setShowManageGdpr } = useContext(GeneralContext)
    
    useEffect(() => {
        CheckCookies()
    }, [])

    // Check whether the cookie options have been allowed yet or not.
    function CheckCookies() {
        let response = Cookies.get('AllowMarketing')

        setMarketing( !response || response === 'false' ? false : true )
    }
  
    return (
           
        <div style={styles.container}>

            <div
            style={styles.blackBackground}
            onClick={() => setShowManageGdpr(false)}
            />

            <div style={styles.box}>

                <div style={styles.title}>

                    <BoldText text={`Cookie settings`} />

                    {/* Close X icon */}
                    <button
                    style={styles.close}
                    onClick={() => setShowManageGdpr(false)}
                    >
                        <svg
                        viewBox="0 0 15.359 15.359"
                        fill={'#000'}
                        height={'1em'}
                        width={'2em'}
                        >
                            <g transform="translate(-1331.813 -19.857)">
                                <g transform="translate(412.455 -939.753) rotate(45)">
                                    <path d="M1349.452,27.563v1.8h-19.921v-1.8Z"/>
                                </g>
                                <g transform="translate(372.202 954.574) rotate(-45)">
                                    <path d="M1349.452,27.563v1.8h-19.921v-1.8Z"/>
                                </g>
                            </g>
                        </svg>
                    </button>

                </div>

                {/* The label ensures screen readers understand the text pertains to the toggle */}
                <label style={styles.option}>

                    <span><RegularText text={`Marketing cookies`} /></span>
                    <Switch
                    onChange={() => (
                        !marketing ? ( ReactPixel.grantConsent(), Cookies.set('AllowMarketing', true) ) : ( ReactPixel.revokeConsent(), Cookies.set('AllowMarketing', false) ),
                        setMarketing(!marketing),
                        setShowCookiesBanner(false)
                    )}
                    checked={marketing}
                    />

                </label>

                <SmallRegularText text="We measure audience engagement and site statistics to understand how our services are used  to improve the quality of our services and develop new ones and deliver and measure the effectiveness of ads. Opt in here to help us improve your experience." />

            </div>

                             
            
        </div>
        
    )
}

export default ManageGdprMobile;