import React from 'react'
import { Link } from 'react-router-dom'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import MediumBoldText from '../../Components/Text/MediumBoldText'
import RegularText from '../../Components/Text/RegularText'
import SmallRegularText from '../../Components/Text/SmallRegularText'
import BoldText from '../../Components/Text/BoldText'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './stylesMobile'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'
// Assets.
import Promotion from '../../Assets/promotion.png'
import GoogleBadge from '../../Assets/googleBadge.png'
import AppleBadge from '../../Assets/appleBadge.png'

function Subscribe() {
    const [ width, height ] = WindowSize()
  
    return (
           
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>

                <BigBoldText text={`This new Art world is ready!`} />

                <div style={{ marginTop: 30, marginBottom: 30 }}>
                    <MediumBoldText text={`Available on both IOS and Android - join now!`} />
                </div>

                <img
                onClick={() => window.open('https://apps.apple.com/gb/app/fair-art-fair/id1582203787', '_blank')}
                style={styles.badge}
                src={AppleBadge}
                />
                
                <img
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fairartfair', '_blank')}
                style={styles.badge}
                src={GoogleBadge}
                />

                <div style={styles.promotionContainer}>
                
                    <div>
                        <RegularText text={`Tools, organisation, connections, support…`} />
                        <p style={{padding: 0, margin: 0}}>&nbsp;</p>
                        <RegularText text={`From £15 a month!`} />
                        <SmallRegularText text={`(annual and monthly subscription available)`} />
                    </div>
                </div>

            </div>

            <div style={Object.assign({}, styles.innerContainer, {display: 'flex', flexDirection: 'column', justifyContent: 'center'})}>

                <MediumBoldText text={`Remember what you get...`} />

                <div style={{display: 'flex', flexDirection: 'row', marginTop: 30, marginBottom: 30, width: '90vw'}}>

                    <div style={styles.verticalSection}>
                        <BoldText text={`Artist benefits`} />
                        <SmallRegularText text={`\nProfessionalise your practice with tools that let you create immediate studio inventories, portfolios, invoices, authenticity certificates and more… just click, swipe and send`} />
                        <SmallRegularText text={`\nGrab opportunities to be selected, discovered and curated for exhibitions, Art prizes, awards and grants`} />
                        <SmallRegularText text={`\nAccess unlimited digital storage – upload, store and organise as many artworks as you like`} />
                        <SmallRegularText text={`\nConnect, share and learn with monthly insights, advice and mentoring from validated Artists.`} />
                        <SmallRegularText text={`\nTap into a wealth of valuable information through our Helpful Matrix, from trusted Art businesses to essential best practice guides`} />
                        <SmallRegularText text={`\nFind a studio, explore residency opportunities, announce an exhibition or promote a skill…our noticeboard is there for whatever you need to get noticed`} />
                        <SmallRegularText text={`\nBe inspired by Artist-validated work through our calendar of exhibitions`} />
                        <SmallRegularText text={`\nGet support, support, support – whatever you need, we’ve got your back`} />
                    </div>

                    <div style={Object.assign({}, styles.verticalSection, {marginLeft: '5vw', marginRight: '5vw'})}>
                        <BoldText text={`Art Lover benefits`} />
                        <SmallRegularText text={`\nBroaden your horizons and connect with pre-vetted and validated Artists from across the globe`} />                        
                        <SmallRegularText text={`\nBuy directly from the Artist’s studio – no middleman, no commission`} />                        
                        <SmallRegularText text={`\nCatalogue and manage your collections in a secure environment `} />                        
                        <SmallRegularText text={`\nCreate wish lists, favourite works and stay in touch with Artists you discover`} />                        
                        <SmallRegularText text={`\nAccess the knowledge, experience and tools you need through our Helpful Matrix`} />                        
                        <SmallRegularText text={`\nSee Art, discover and collect through our calendar of exhibitions`} />                        
                    </div>

                    <div style={styles.verticalSection}>
                        <BoldText text={`Curator benefits`} />
                        <SmallRegularText text={`\nDevelop your network as you discover and connect with our community of pre-vetted Artists`} />                        
                        <SmallRegularText text={`\nBuild your own exhibitions and produce documentation with our simple-to-use professional tools`} />                        
                        <SmallRegularText text={`\nMake your work stand out with opportunities to have your exhibitions featured`} />                        
                        <SmallRegularText text={`\nCreate exhibition inventories and selections of Artists and artworks – just swipe, click and send`} />                        
                        <SmallRegularText text={`\nAccess the Helpful Matrix, our little black book of the Art world`} />                       
                        <SmallRegularText text={`\nVisit the noticeboard for anything from finding a technician, framer or fine Art transport to putting out open calls for Artists.\n\n`} />

                        <BoldText text={`Art curious...`} />
                        <SmallRegularText text={`You Art always free.`} />
                    </div>

                </div>

            </div>

            <FooterMobile />
        </div>
        
    )
}

export default Subscribe;