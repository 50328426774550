export default {

    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        margin: 'auto',
        paddingLeft: '2vw',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        overflow: 'hidden'
    }
    
}