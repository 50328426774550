import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'

function AboutOne() {
  
    return <BigBoldText text={`First\nArtist-founded\nArtist-validated\nArtist-run\ncommunity.`} />

}

export default AboutOne;