import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function Faqs() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>
                
                <BigBoldText text="FAQs" marginBottom='0.5em' />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                <BoldText text={`What is Fair Art Fair?`} />
                <RegularText text={`We are a new world of Art, and mobile app providing opportunities for artist, art lovers and curators to find each other, to buy and sell, organise and collect all in a transparent confident environment that is 0% commission and 100% support. By creating a platform that serves all Art interested parties with a sustainable subscription business model.\n\n`} />

                <BoldText text={`Monthly or Yearly?`} />
                <RegularText text={`You choose, but we advise yearly.  Firstly it is less expensive and the tools that we’ve developed for you improve with time and engagement. Annual subscriptions allow us to create more sustainable benefits to our community of Artists and Art Lovers.  However we understand that monthly has its advantages especially when it comes to cash flow for artists. We also applaud that and Art Lover can come and discover and buy for just a month if so desired!\n\n`} />

                <BoldText text={`How does my subscription support the wider Fair Art Fair community?`} />
                <RegularText text={`We know how to be Fair.  By setting the same subscription amount for all users we allow everyone to support the platform.  Artists have the opportunity to be seen and sell without commission and Art Lovers organise collections and buy without commission.  One sale or one purchase saves the premiums instantly.\n\n`} />

                <BoldText text={`Do I need to subscribe in order to purchase an artwork?`} />
                <RegularText text={`Yes, as a subscriber you will be able to access Art directly and buy commission free. Artists sell your work commission free.  We are a 0% Commission space, all subscriptions contribute to supporting all our initiatives.  We believe Art should be accessible to all, Art Curious users, you can see an exhibition and experience Art on the app for free to connect and buy or sell simply subscribe.\n\n`} />

                <BoldText text={`Is the app available on both iOS and Android?`} />
                <RegularText text={`Yes, you can access Fair Art Fair through iOS and Android.\n\n`} />

                <BoldText text={`How does Fair Art Fair protect me?`} />
                <RegularText text={`•	We follow industry standard best security practices across all our software, with our development culture always focused on security first, starting from the ground up with OWASP Top 10.`} />
                <RegularText text={`•	We encrypt all sensitive user data and only store user data necessary for the software to function.`} />
                <RegularText text={`•	Our backend is built on AWS, an industry leading fully managed service implementing industry best practices to ensure the security of all our user data.\n\n`} />

                <RegularText text={`Please see our T&Cs and community guidelines for more.\n\n`} />

                <BoldText text={`How do I become a curated Artist?`} />
                <RegularText text={`We believe in the capacity for us to lift each other, so peer-to-peer review is a crucial and defining aspect of Fair Art Fair. We also know Art Lovers often need guidance to find the works they want to buy. We have advisory board of curators and guest selectors engaged already and this is a aspect of Fair Art Fair that will improve and grow. We look forward to peer to peer lifting through our liking metrics so stay tuned and stay engaged. We promise to respond to the needs of the community with curators for digital selections as well as in real life exhibitions. Our community of professional Artists have established reputations and experience that enables us to vet and select newcomer Artists into the group, providing them with even more opportunities.\n\n`} />

                <RegularText text={`To be considered you just subscribe, when you subscribe you get competitively priced professional tools, unlimited uploads - auto populating documentation (unique to FAF), ability to communicate with fellow artists on the platform and gain support and advise through the Helpful Matrix.\n\n`} />

                <RegularText text={`Fair Art Fair is creating a New World of Art. A platform of discovery and connection with industry and peer to peer validation.  We are an App of integrity based on 11 years of Gallery/Residencies supporting over 400 artists with space and time to grow their practices, be supported and mentored and benefit from our wide and respected network. We have an established reputation for curating and selecting artists of excellence and many have gone on the museum collections and curation.  We know our curation and selection process is crucial for the App and we have launched it to do many things and create benefits for many more artists than our IRL space is limited to.\n\n`} /> 

                <RegularText text={`We developed two arenas for Art Lovers to search for works to collect. Curated arena is for artists that have been curated by the directors or by guest curators/selectors and the Total Discovery mode where newcomer artists are initially visible. It is here that you will have the continuing opportunity to sell your work and be selected, promoted and placed in exhibitions and have your profile visible. As New York Times said “this app’s success will rely on good curation” and this is our plan.\n\n`} />

                <RegularText text={`We truly are endeavour to re-invent the art world and allow artists full agency over their practices and meet interested curators and art lovers directly and this is an ambitious but achievable goal and requires transparency and integrity.\n\n`} />

                <BoldText text={`I’m a curator, can I bring my Artists onto the platform?`} />
                <RegularText text={`Yes – we love discovering new talent and Artistic practices. All Artists curated into your exhibitions will also be invited to subscribe.\n\n`} />

                <BoldText text={`What is the Helpful Matrix?`} />
                <RegularText text={`We have extensively researched and studied the needs of Artists, Art Lovers, and Curators, synthesizing it into a dynamic usable database. The Helpful Matrix will grow with use, so if you can’t find what you need or you’re looking for something specific, just ask and we’ll find it for you.`} />
                <RegularText text={`Want to write an Artist statement, or create a professional bio? Perhaps you want to understand consignment agreements? Or maybe you need a trusted installer to hang a painting or pack and ship a treasured artwork? The Helpful Matrix has all the answers.\n\n`} />

                <BoldText text={`Is Fair Art Fair connected to any art fairs?`} />
                <RegularText text={`With a name like Fair Art Fair, how could we not?  We have big plans and love in real life exhibitions.  Our first IRL exhibition curated by 4 top London Curators selected exclusively from the subscriber population was hosted at Unit 1 Gallery-Workshop in October 2021 Stay tuned for more of these phygital experiences with a view to our own Fair Art Fair!\n\n`} />

                <BoldText text={`How do I cancel my subscription?`} />
                <RegularText text={`Within the app we provide an easy and transparent way to manage your account and subscription, so cancelling is only a few clicks away. You can cancel and continue using the subscription until the next billing date.\n\n`} />

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <RegularText text={`Got a question we haven’t thought of? Get in touch`} />
                    <BoldText type='email' text="&nbsp;here." />
                </div>

                </div>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default Faqs;