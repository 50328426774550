import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function SubscriptionInformation() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>
            
            <div style={styles.innerContainer}>
                
                <BigBoldText text={`Subscription information`} />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                    <RegularText text={`One Role (Artist, Art Lover or Curator) is £15.00 per month billed annually (£180.00) or £19.99 per month on a monthly subscription. `} />
                    <RegularText text={`- Provides access to all management and communication tools (for subscription details click here)`} />
                    <RegularText text={`\nTwo Roles + £5.00`} />
                    <RegularText text={`- Adding an additional role to your subscription (Role 2) is an additional £5.00 per month and will only be billed for the remainder of your current subscription.`} />
                    <RegularText text={`\nThree Roles + £5.00`} />
                    <RegularText text={`- Adding a third role (Role 3) is an additional £5.00 per month and will only be billed for the remainder of your current subscription.`} />
                    <RegularText text={`\nAdditional roles will activate immediately. You can remove additional roles at any time through your Fair Art Fair Manage subscription(s) page. Removing additional roles will take affect only after the current subscription has expired.`} />
                    <RegularText text={`\nSubscriptions are auto-renewing and can be cancelled at any time through the App store. Subscribers are able to delete their account at any time as long as their active subscriptions have been cancelled through the App store.`} />
                    <RegularText text={`\nSubscriptions using discount codes or free offers will be auto-renewed upon expiry at the standard subscription price. All subscribers will be notified in advance of the auto-renewal and will be given the option to opt out of the auto-renewal.`} /> 

                </div>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default SubscriptionInformation;