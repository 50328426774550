import React from 'react'
// Components.
import RegularText from '../../../Components/Text/RegularText'
// Styling.
import styles from './styles'
import WindowSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'

function AboutThree() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
                
            <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                <div style={styles.innerContainer}>

                    <div style={styles.section}>
                        <RegularText text={`Accessibility is at the heart of Fair Art\nFair: our subscription price is low and\nour benefits are high. When you\nsubscribe to Fair Art Fair, you are\nhelping to build a new world of Art.\nYour subscription gets you amazing\ntools and support but most\nimportantly it enriches the entire\ncommunity to thrive.`} />
                    </div>

                    <div style={styles.section2}>
                        <RegularText text={`We know that for every known Artist\nthere are hundreds of Artists yet to\nbe discovered. Fair Art Fair is an\nexciting new step on that journey of\ndiscovery.`} />
                    </div>

                </div>

            </div>
            
        )
  
    // mobile version
    return (
            
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={Object.assign({}, styles.innerContainer, { flexDirection: 'column' })}>

                <div style={styles.sectionMobile}>
                    <RegularText text={`Accessibility is at the heart of Fair Art\nFair: our subscription price is low and\nour benefits are high. When you\nsubscribe to Fair Art Fair, you are\nhelping to build a new world of Art.\nWhat you spend through Fair Art Fair\nwill directly feed back into and\nsupport the Artist community within it.`} />
                </div>

                <div style={styles.section2Mobile}>
                    <RegularText text={`We know that for every known Artist\nthere are hundreds of Artists yet to\nbe discovered. Fair Art Fair is an\nexciting new step on that journey of\ndiscovery.`} />
                </div>

            </div>

        </div>
        
    )
}

export default AboutThree;