import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'

function SubscriberCount() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>

                <BigBoldText text="App Changelog" marginBottom='0.5em' />

                <BoldText text="29/03/2022 - Hot Fix" marginBottom='0.5em' />

                <RegularText text={`
                - Added skeleton loading to most screens
                - Fixed bug where scrolling when viewing an artists works, it wouldn’t show more than 10 results
                - Fixed bug where create album modal wouldn’t display in some circumstances
                `}
                marginBottom='3em'
                />

                <BoldText text="25/03/2022" marginBottom='0.5em' />

                <RegularText text={`
                - You can now click to view your current location on the map view
                - You can now view an artist’s studio location on their profile and open it in Google/Apple maps (device dependant)
                - Creating/editing artwork, tags and mediums will now save and suggest your last 5 typed inputs and country will show your last selection for faster editing
                - Fixed bug on archived exhibitions causing app crash
                - Womxn in art is now randomised
                - Fixed bug where multi selecting artwork to add to an album wouldn't correctly show the list of your albums
                - Fixed bug where scrolling on an album wouldn't show all artworks
                - Added view artwork images scroll indicator
                - Clearing your search input will now re-pull the standard results to ensure you aren’t left staring at a blank screen!
                - Combined my account and personal information screens as it didn't make sense for them to be split
                - You can now see the partnership/company your account is associated with in your settings screen
                - Bug where clicking create a new album would show your existing albums rather than the create new album screen
                - Subscription management screen updated to more clearly display your current monthly or yearly subscription. The cancelled status of your subscription and improved clarity of what’s happening whilst loading payment
                - Fixed bug where saving artwork would crash due to missing URL
                `}
                marginBottom='3em'
                />

                <BoldText text="05/03/2022" marginBottom='0.5em' />

                <RegularText text={`
                - You can now edit an artworks albums from with an artwork
                - Fixed bug where creating a notice wouldn’t correctly save the private date expiry time
                - Fixed bug where choose certain times for a notice’s private viewing would stop you being able to publish it
                - Fixed bug stopping you being able to delete your account under certain circumstances
                - You can now subscribe monthly as well as yearly
                - Added the Female Artists view to gridhub
                `}
                marginBottom='3em'
                />

                <BoldText text="01/03/2022" marginBottom='0.5em' />

                <RegularText text={`
                - Fixed bug which would stop event notices being created if a private view date/time were included
                - Added ability to choose your studio location via a map rather than typing in the address
                - Fixed bug where you had a 20% chance of the launch video not loading on the app.
                - Edit your artist profile and/or curator profile from the hub screen rather than the drawer menu for easier access.
                - Fixed bug where editing your profile wouldn’t save
                - Fixed bug where add artwork button on inventory and collection wouldn’t display
                - Improved clarify of creating new artworks and albums
                - Fixed bug where an album wouldn’t show more than 10 artworks
                - You can now delete your video from your profile instead of being required to replace it with another
                - Fixed bug on album reorder view where, if scrolled, albums would not stick to your finger correctly
                - Added ability to location your business on the map rather than just typing in your address.
                - Added ability to tag a notice by location using a map.
                `}
                marginBottom='3em'
                />

                <BoldText text="09/02/2022" marginBottom='0.5em' />

                <RegularText text={`
                - Improved clarify for adding artworks to album.
                - Added direct messaging - you can now message artists.
                - You can now jump straight back to the home screen by clicking the ‘Fair Art Fair’ logo at the top of any screen.
                - Notice ‘opportunities’ now show their titles.
                - Extensive UI/UX improvements on all screens.
                - Moved location of edit account details and personal information for easier access.
                - Fixed bug stopping you export inventory/collection to PDF with no price.
                - You can now view any of your albums on the exhibition wall.
                - Fixed bug where exhibition ‘3D View’ links would occasionally not open correctly.
                - Artists can now mark themselves as ‘open to commission’
                - You can now view an artists albums and the artwork they’ve chosen for each album.
                - You can now drag and drop to order your albums.
                - Notices were only showing expiry date not start date.
                - Notices now show dates as DD-MM-YYYY rather than YYYY-MM-DD
                - Notice time selector, certain text elements where white on a white background, now rectified.
                - Added additional launch videos for variation.
                - Fixed bug where deleting certain public artworks was disabled.
                - Fixed bug where notice board would pull view up to top of the list when reaching the bottom.
                `}
                marginBottom='3em'
                />

                <BoldText text="17/12/2021 - Hot Fix" marginBottom='0.5em' />

                <RegularText text={`
                - Fixed crash when refreshing Collection screen
                - You can now select your gender
                `}
                marginBottom='3em'
                />

                <BoldText text="13/12/2021 - Hot Fix" marginBottom='0.5em' />

                <RegularText text={`
                - Fixed bug where refreshing 'My Public Selections' would return no results
                - Favourites now indicate clearly what role's favourites you are viewing
                - Fixed crashing in My Exhibitions
                - Fixed being able to swipe to open the menu when not logged in.
                - Fixed selections omitting artworks for chosen curator
                - Fixed a crashing bug linked to a selection of screens
                - Album list will now refresh automatically after deleting an album
                `}
                marginBottom='3em'
                />

                <BoldText text="10/12/2021" marginBottom='0.5em' />

                <RegularText text={`
                - Log in screen improved to detect autofill more accurately
                - Added option to add a URL to an exhibition e.g for viewing a 3D version.
                - Curating an exhibition now allows you to credit multiple curators.
                - Posting a notice, you can now add an image
                - You can now search artworks by completed date
                - Fixed mising titled search box in notice board.
                - Fixed bug stopping you adding an artwork to an exhibition
                - Cancelling reordering images for an artwork brings you back to the artwork instead of pushing you all the way back to your inventory/collection
                - Added preview exhibition image to manage exhibition hub
                - You can now export multiple selected artworks from your inventory or collection into a single PDF
                - Added 'view favourites' link to artist hub screen
                - Fixed bug where share modal wouldnt appear on gridhub.
                - Updated date and time inputs to a modal selector rather than text inputs.
                - You can now search artists by partial name matches and not just full exact names
                - Updated viewing faf validated artists, artworks and curators.
                - Curators can now create public selections. These are visible in the updated 'FAF Validated' arena, allowing a curator to highlight exceptional Artworks and Artists.
                - Fixed issue with deeply nested view artwork screen causing a loop so you couldnt view multiple artwork.
                - A significant overhaul of the subscription system from a technical aspect to improve robustness accross the board
                - Updated to RN 0.66 bringing with it some minor improvements
                - Fixed bug where 'Your notices' wouldn't populate
                `}
                marginBottom='3em'
                />

                <BoldText text="10/11/2021 - Hot Fix" marginBottom='0.5em' />

                <RegularText text={`
                - Total discovery failed to correctly show newcomer artists. Now rectified.
                - Albums were previously not correctly showing all artworks.
                - Fixed a crash on all artist list when viewing an exhibition that would cause the app to crash.
                - Fixed the forward/back arrows not working as intended on the view artists screens.
                - Fixed issue where viewing an artists 'further works' wouuld not display any works.
                `}
                marginBottom='3em'
                />
                
                <BoldText text="09/11/2021" marginBottom='0.5em' />

                <RegularText text={`
                - Artwork can now be removed from albums
                - Albums can now be deleted
                - Single row works in inventory, collection and exhibition lists now have their titles aligned left, stopping strange center floating.
                - IOS discount code redemption significantly simplified
                - Added an artist's contact email to view profile screens.
                - Curated arena and Total Discovery both defined more ridigly and strengthened.
                - Made it clearer how making an artwork private or visible can be managed.
                - You can now edit an artwork at any time as long as it is not featured in an exhibition.
                - Added menu information to inform user if the app has an update available.
                - Fixed bug where refreshing a single artists list of works would duplicate the works.
                - You can now download a CSV spreadsheet of any exhibitions artwork.
                - Optimisation of lists to improve performance, lowering renderBatchSize and windowSize for Flatlists. This should help scrolling feel snappier and smoother.
                -  Added a 'basic' search mode. So you can now search artworks with a single input, and it will filter all artworks with any parameter that matches your search.
                - The default view of any artist list or artwork list is now a random selection.
                - Fixed issue on rerder/delete images for an artwork where the wrong image would be deleted when clicking delete.
                - Now possible to remove a work from your exhibition from the work notes screen.
                - Brand new country location selector, with shortcut clicks for united kingdom and united states of america and a search function.
                - Added a new app area - 'Focus on Photography'
                - Added Save option on the pop up modals that catch if you try to leave a screen without saving your work.
                - Updated artwork availability input and price input designs to match rest of input modals.
                - Fixed curator notes on artworks was hidden behind the keyboard.
                - You can now swipe to close the drawer menu
                - Adding tags / mediums now has an 'Add' button to complement the enter button on keyboard for clearer functionality.
                `}
                marginBottom='3em'
                />

            </div>

        </div>
    )
}

export default SubscriberCount;