import React from 'react'
// Components.
import RegularText from '../../../Components/Text/RegularText'
import BoldText from '../../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'

function AboutFive() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
                
            <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                <div style={styles.innerContainer}>

                    <div style={styles.section}>

                        <RegularText text={`Fair is a new app-based digital\nplatform designed to create a fairer,\nmore transparent Art world. One that\nrevolves around the artist while also\nmeeting the needs of collectors,\ncurators and the Art curious.`} />
                    </div>

                    <div style={styles.section2}>
                        <RegularText text={`We’ve put our years of experience\nand contacts to work to create a first-\nof-its-kind destination that works for\neveryone. Offering direct purchasing\nwith 0% commission; professional\ntools; advice from industry experts;\npeer-to-peer support; exposure;\nnetworking; exhibitions; professional\nopportunities; and much more.`} />
                    </div>

                </div>

            </div>
            
        )
  
    // mobile version
    return (
            
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={Object.assign({}, styles.innerContainer, { flexDirection: 'column' })}>

                <div style={Object.assign({}, styles.sectionMobile, { flexDirection: 'column' })}>

                    <RegularText text={`Fair is a new app-based digital\nplatform designed to create a fairer,\nmore transparent Art world. One that\nrevolves around the artist while also\nmeeting the needs of collectors,\ncurators and the Art curious.`} />
                </div>

                <div style={styles.section2Mobile}>
                    <RegularText text={`We’ve put our years of experience\nand contacts to work to create a first-\nof-its-kind destination that works for\neveryone. Offering direct purchasing\nwith 0% commission; professional\ntools; advice from industry experts;\npeer-to-peer support; exposure;\nnetworking; exhibitions; professional\nopportunities; and much more.`} />
                </div>

            </div>

        </div>
        
    )
}

export default AboutFive;