import React from 'react'
import { Link } from 'react-router-dom'
// Styling.
import WindowSize from '../General/windowsSize'

function BigBoldText({ text, link, type, marginTop, marginBottom, marginLeft }) {
    const [ width, height ] = WindowSize()

    if(type === 'email')
        return (
            <h1
            onClick={() => {window.location.href = `mailto:contact@fairartfair.art`}}
            style={{
                fontFamily:'franklingothic_demi',
                fontSize: width/48 < 20 ? 20 : width/48 > 35 ? 35 : width/48,
                color: '#000',
                letterSpacing: -0.3,
                lineHeight: 1.1,
                marginTop: !marginTop ? 0 : marginTop,
                marginBottom: !marginBottom ? 0 : marginBottom,
                marginLeft: !marginLeft ? 0 : marginLeft,
                padding: 0,
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                cursor: 'pointer'
            }}
            >
                {text}
            </h1>
        )

    return (
        <Link to={() => link} style={{textDecoration: 'none'}}>
            <h1
            style={{
                fontFamily:'franklingothic_demi',
                fontSize: width/48 < 20 ? 20 : width/48 > 35 ? 35 : width/48,
                color: '#000',
                letterSpacing: -0.3,
                lineHeight: 1.2,
                marginTop: !marginTop ? 0 : marginTop,
                marginBottom: !marginBottom ? 0 : marginBottom,
                marginLeft: !marginLeft ? 0 : marginLeft,
                padding: 0,
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                cursor: 'pointer'
            }}
            >
                {text}
            </h1>
        </Link>
    )
}

export default BigBoldText;