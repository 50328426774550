import React from 'react'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function JourneyFive() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/rp83YWm.jpg'}
                />

                {/* <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                    <BoldText text={`Professionalise your\npractice with intuitive tools`} />

                    <RegularText text={`Upload your artworks to\nour unlimited digital\nstorage, create studio\ninventories, manage\nyour portfolio, produce\ninvoices and certificates\n– swipe, click, send.`} />

                </div> */}
            </>
        )
  
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/yopsTg9.jpg'}
            />

            {/* <div style={Object.assign({}, styles.containerMobile, { width: '90vw' })}>

                <BoldText text={`Professionalise your practice with intuitive tools`} />

                <RegularText text={`Upload your artworks to our unlimited digital storage, create studio inventories, manage your portfolio, produce invoices and certificates – swipe, click, send.`} />

            </div> */}

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default JourneyFive;