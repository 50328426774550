export default {

    container: {
        paddingTop: '10vh',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    innerContainer : {
        marginBottom: '5em'
    },

    item: {
        marginRight: '3em'
    }

}