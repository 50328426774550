import React from 'react'
import { useSpring, animated, config } from '@react-spring/web'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function ArtLoverTwo() {
    const [ width, height ] = WindowSize()

    const movement = useSpring({
        from: {opacity: 0, transform: 'translateY(100px)'},
        to: {opacity: 1, transform: 'translateY(0)'},
        config: config.molasses,
        delay: 800
    })

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/jlvCUQA.jpg'}
                />

                <animated.div style={Object.assign({}, styles.container, movement, { width: width, height: height })}>

                    <BoldText text={`Discover amazing art`} />

                    <RegularText text={`We make it easy to find great\nArt from across the globe –\nand it’s all peer-validated.`} />

                </animated.div>
            </>
        )
  
    // mobile version
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/coSvm9f.jpg'}
            />

            <animated.div style={Object.assign({}, styles.containerMobile, movement, { width: '90vw' })}>

                <BoldText text={`Discover amazing art`} />

                <RegularText text={`We make it easy to find great Art from across the globe – and it’s all peer-validated.`} />

            </animated.div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default ArtLoverTwo;