import { useState } from 'react'
import axios from 'axios'
import config from '../aws-exports'

export default () => {
    const [ loading, setLoading ] = useState( 'static' )
    const [ result, setResult ] = useState()

    const baseUrl = axios.create({
        baseURL: config.aws_cloud_logic_custom[0].endpoint,
    })

    async function SubscriberCount() {

        try {
            setLoading('loading')

            const response = await baseUrl.get('/web/stats/users')

            setResult(response)
        
            setLoading('loaded')

        } catch (err) {
            console.log('error:', err)
            setLoading('static')
        }
    }

    return [ SubscriberCount, loading, result ]
}