import React from 'react'
import { Link } from 'react-router-dom'
// Styling.
import WindowSize from '../General/windowsSize'

function MediumBoldText({ text, link, marginTop }) {
    const [ width, height ] = WindowSize()

    return (
        <Link to={() => link} style={{textDecoration: 'none'}}>
            <h1
            style={{
                fontFamily:'franklingothic_demi',
                fontSize: width/17 < 40 ? 40 : width/17 > 100 ? 100 : width/17,
                color: '#000',
                letterSpacing: -1,
                lineHeight: 1,
                marginTop: !marginTop ? 0 : marginTop,
                marginBottom: 0,
                padding: 0,
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                cursor: link === undefined ? 'default' : 'pointer'
            }}
            >
                {text}
            </h1>
        </Link>
    )
}

export default MediumBoldText;