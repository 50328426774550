import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSwipeable } from "react-swipeable"
//Styling.
import styles from './AppStyles'
import WindowSize from './Components/General/windowsSize'
import Breakpoints from './Components/General/breakpoints'

// Need to have this content function nested inside App otherwise it doesnt get access to history of router.
function Content({ path, Component, forward, back, disabled, setDisabled }) {
    let history = useHistory()
    const [ width, height ] = WindowSize()

    // user swipe actions (for mobile)
    const handlers = useSwipeable({
        onSwipedDown: (eventData) => back === 'null' || disabled === true ?
                (console.log(null), setDisabled(true))
            :
                history.push(back),
        onSwipedUp: (eventData) => forward === 'null' || disabled === true ?
                console.log(null)
            :
                (history.push(forward), setDisabled(true))
    })

    if( path === '/10' )
        return  <Component />

    else if( path === '/' && width <= Breakpoints.mobile)
        return  <Component />

    return (
        <div
        style={Object.assign({}, width > Breakpoints.mobile ? styles.container : styles.containerMobile, { height: height })}
        {...handlers}
        // Listen to direction of scroll to decide what direction to redirect user to.
        onWheel={event => disabled === true ?
                console.log(null)
                :
                    event.nativeEvent.wheelDelta > 0 ?
                        back === 'null' ?
                            console.log(null)
                        :
                            (history.push(back), setDisabled(true))
                    :
                        forward === 'null' ?
                            console.log(null)
                        :
                            (history.push(forward), setDisabled(true))
        }>

            <Component />

        </div>
    )
}

export default Content;