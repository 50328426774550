import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
// Components.
import MobileVersion from './indexMobile'
import BigBoldText from '../../Components/Text/BigBoldText'
import MediumBoldText from '../../Components/Text/MediumBoldText'
import RegularText from '../../Components/Text/RegularText'
import SmallRegularText from '../../Components/Text/SmallRegularText'
import BoldText from '../../Components/Text/BoldText'
import Footer from '../../Components/Footer/index'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'
// Assets.
import Promotion from '../../Assets/promotion.png'
import GoogleBadge from '../../Assets/googleBadge.png'
import AppleBadge from '../../Assets/appleBadge.png'

function Subscribe() {
    const [ width, height ] = WindowSize()

    useEffect(() => {
        ReactPixel.track('ViewSubscribePage', true)
    }, [])

    // display mobile version of the page if need be.
    if(width <= Breakpoints.mobile)
        return <MobileVersion />
  
    return (
           
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>

                <BigBoldText text={`This new Art world is ready!`} marginBottom='1em' />

                <div style={{ marginLeft: '10vw' }}>
                    <MediumBoldText text={`Available on both IOS and Android - join now!`} />
                </div>
                
                <div style={styles.promotionContainer}>

                    <div style={styles.badgeContainer}>

                        <img
                        onClick={() => window.open('https://apps.apple.com/gb/app/fair-art-fair/id1582203787', '_blank')}
                        style={{height: 75, marginTop: 10, cursor: 'pointer'}}
                        src={AppleBadge}
                        />
                        
                        <img
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fairartfair', '_blank')}
                        style={{height: 75, marginTop: 20, cursor: 'pointer'}}
                        src={GoogleBadge}
                        />
                        
                    </div>

                    <div style={{paddingBottom: 10}}>

                        <RegularText text={`Tools, organisation, connections, support…`} />

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <RegularText text={`From £15 a month!`} />
                            <p style={{padding: 0, margin: 0}}>&nbsp;</p>
                            <SmallRegularText text={`(annual and monthly subscription available)`} />
                        </div>

                    </div>

                </div>

            </div>

            <div style={Object.assign({}, styles.innerContainer, {display: 'flex', flexDirection: 'column', justifyContent: 'center'})}>

                <MediumBoldText text={`Remember what you get...`} />

                <div style={{display: 'flex', flexDirection: 'row', marginTop: '4em'}}>

                    <div style={styles.verticalSection}>
                        <BoldText text={`Artist benefits`} />
                        <SmallRegularText text={`\nProfessionalise your practice with tools that let you create immediate studio inventories, portfolios, invoices, authenticity certificates and more… just click, swipe and send`} />
                        <SmallRegularText text={`Grab opportunities to be selected, discovered and curated for exhibitions, Art prizes, awards and grants`} marginTop='1.3em' />
                        <SmallRegularText text={`Access unlimited digital storage – upload, store and organise as many artworks as you like`} marginTop='1.3em' />
                        <SmallRegularText text={`Connect, share and learn with monthly insights, advice and mentoring from validated Artists.`} marginTop='1.3em' />
                        <SmallRegularText text={`Tap into a wealth of valuable information through our Helpful Matrix, from trusted Art businesses to essential best practice guides`} marginTop='1.3em' />
                        <SmallRegularText text={`Find a studio, explore residency opportunities, announce an exhibition or promote a skill…our noticeboard is there for whatever you need to get noticed`} marginTop='1.3em' />
                        <SmallRegularText text={`Be inspired by Artist-validated work through our calendar of exhibitions`} marginTop='1.3em' />
                        <SmallRegularText text={`Get support, support, support – whatever you need, we’ve got your back`} marginTop='1.3em' />
                    </div>

                    <div style={Object.assign({}, styles.verticalSection, {marginLeft: '5vw', marginRight: '5vw'})}>
                        <BoldText text={`Art Lover benefits`} />
                        <SmallRegularText text={`\nBroaden your horizons and connect with pre-vetted and validated Artists from across the globe`} />                        
                        <SmallRegularText text={`Buy directly from the Artist’s studio – no middleman, no commission`} marginTop='1.3em' />                        
                        <SmallRegularText text={`Catalogue and manage your collections in a secure environment `} marginTop='1.3em' />                        
                        <SmallRegularText text={`Create wish lists, favourite works and stay in touch with Artists you discover`} marginTop='1.3em' />                        
                        <SmallRegularText text={`Access the knowledge, experience and tools you need through our Helpful Matrix`} marginTop='1.3em' />                        
                        <SmallRegularText text={`See Art, discover and collect through our calendar of exhibitions`} marginTop='1.3em' />                        
                    </div>

                    <div style={styles.verticalSection}>
                        <BoldText text={`Curator benefits`} />
                        <SmallRegularText text={`\nDevelop your network as you discover and connect with our community of pre-vetted Artists`} />                        
                        <SmallRegularText text={`Build your own exhibitions and produce documentation with our simple-to-use professional tools`} marginTop='1.3em' />                        
                        <SmallRegularText text={`Make your work stand out with opportunities to have your exhibitions featured`} marginTop='1.3em' />                        
                        <SmallRegularText text={`Create exhibition inventories and selections of Artists and artworks – just swipe, click and send`} marginTop='1.3em' />                        
                        <SmallRegularText text={`Access the Helpful Matrix, our little black book of the Art world`} marginTop='1.3em' />                       
                        <SmallRegularText text={`Visit the noticeboard for anything from finding a technician, framer or fine Art transport to putting out open calls for Artists.\n\n\n\n`} marginTop='1.3em' />

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <BoldText text={`Art curious...`} />
                            <SmallRegularText text={`You Art always free.`} marginTop='1.3em' /> 
                        </div>                
                    </div>

                </div>

            </div>

            <Footer />

        </div>
        
    )
}

export default Subscribe;