import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { InView } from 'react-intersection-observer'
import { useSwipeable } from "react-swipeable"
// Components.
import BigBoldText from '../../../Components/Text/BigBoldText'
import ContentItem from './contentItem'
import Footer from '../../../Components/Footer'
import FooterMobile from '../../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'

function HomeTen() {
    const [ width, height ] = WindowSize()
    let history = useHistory()
    const [ visible, setvisible ] = useState()

    // user swipe actions (for mobile)
    const handlers = useSwipeable({
        onSwipedDown: (eventData) => visible === false ?
                console.log(null)
            :
                history.push('/9')
    })
  
    return (

        <div
        style={Object.assign({}, styles.container, { width: width, height: height })}
        {...handlers}
        // Listen to direction of scroll to decide what direction to redirect user to.
        onWheel={event => visible === true && event.nativeEvent.wheelDelta > 0 ?
                history.push('/9')
            :
                console.log(null)
        }>

            {/* This tells us when the nav is in view of the user or not (i.e means we know when the user is at the top of the screen or not) */}
            <InView as="div" onChange={(inView, entry) => setvisible(inView)} />

            <div style={Object.assign({}, styles.innerContainer, {
                marginTop: 65
            })}>
                <BigBoldText text={`Why Fair Art Fair?`} />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                    <div style={styles.innerContent}>
                        <ContentItem title={`An established community`} text={`centered on peer-validated Artists`} />
                        <ContentItem title={`Digital tools`} text={`everything the Artist, Art Lover or\nCurator could ever want`} />
                        <ContentItem title={`0% commission`} text={`on all artworks`} />
                        <ContentItem title={`Newcomer opportunities`} text={`helping new talent get discovered and mentored`} />
                    </div>

                    <div style={styles.innerContent}>
                        <ContentItem title={`Unrivalled connections`} text={`for building a full-circle Art community`} />
                        <ContentItem title={`Professional support`} text={`from industry experts and peers to\nhelp you thrive`} />
                        <ContentItem title={`The Helpful Matrix`} text={`your little black book for the Art world`} />
                    </div>

                </div>

            </div>        

            {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
        </div>
        
    )
}

export default HomeTen;