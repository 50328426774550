import React from 'react'
import { useSpring, animated, config } from '@react-spring/web'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function JourneyOne() {
    const [ width, height ] = WindowSize()

    const movement = useSpring({
        from: {opacity: 0, transform: 'translateY(100px)'},
        to: {opacity: 1, transform: 'translateY(0)'},
        config: config.molasses,
        delay: 800
    })

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/BXiVcT5.jpg'}
                />

                <animated.div style={Object.assign({}, styles.container, movement, { width: width, height: height })}>

                    <BoldText text={`Be part of an empowering\ncommunity`} />

                    <RegularText text={`You Art why we exist – Fair Art\nFair is all about connecting\nyou to fellow artists and\nmaking it easy to access\npeer-to-peer support,\nmentorships and more.`} />

                </animated.div>
            </>
        )
  
    // mobile version
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/1VT4Qql.jpg'}
            />

            <animated.div style={Object.assign({}, styles.containerMobile, movement, { width: '90vw' })}>

                <BoldText text={`Be part of an empowering community`} />

                <RegularText text={`You Art why we exist – Fair Art Fair is all about connecting you to fellow artists and making it easy to access peer-to-peer support, mentorships and more.`} />

            </animated.div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default JourneyOne;