
export default {

    container: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 11,
        top: 0,
        minWidth: '100%',
        minHeight: '100%',
        margin: 0,
    },

    blackBackground: {
        display: 'flex',
        flex: 1,
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 11,
        minWidth: '100%',
        minHeight: '100%',
        margin: 0,
        backgroundColor: '#0000004D',
    },

    box: {
        zIndex: 12,
        width: 500,
        maxWidth: 500,
        margin: 0,
        paddingTop: '2vh',
        paddingBottom: '2vh',
        paddingLeft:'2vw',
        paddingRight:'2vw',
        backgroundColor: '#fff',
    },

    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    close: {
        backgroundColor: 'transparent',
        border: 0,
        cursor: 'pointer'
    },

    option: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 25,
        marginBottom: 10
    }

}