import React from 'react'
import { useSpring, animated, config } from '@react-spring/web'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function CuratorSeven() {
    const [ width, height ] = WindowSize()

    const movement = useSpring({
        from: {opacity: 0, transform: 'translateY(100px)'},
        to: {opacity: 1, transform: 'translateY(0)'},
        config: config.molasses,
        delay: 800
    })

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/Juwtb8U.jpg'}
                />

                <animated.div style={Object.assign({}, styles.container, movement, { width: width, height: height })}>

                    <BoldText text={`Be supported`} />

                    <RegularText text={`Through our portal you can\ninstantly create custom PDFs,\nselections of works and\ndocumentation, and keep\ntrack of your discoveries.\nNeed a trusted framer? A\nreliable shipper? Freelance\ntechnical work? Our Helpful\nMatrix and Notice Board plug\nyou in to all the know-how you\nneed.`} />

                </animated.div>
            </>
        )
  
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/A9Uv50g.jpg'}
            />

            <animated.div style={Object.assign({}, styles.containerMobile, movement, { width: '90vw' })}>

                <BoldText text={`Be supported`} />

                <RegularText text={`Through our portal you can instantly create custom PDFs, selections of works and documentation, and keep track of your discoveries. Need a trusted framer? A reliable shipper? Freelance technical work? Our Helpful Matrix and Notice Board plug you in to all the know-how you need.`} />

            </animated.div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default CuratorSeven;