export default {

    container: {
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-between',
        zIndex: 10,
        height: 65
    },

    containerMobile: {
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-between',
        backgroundColor: 'transparent',
        zIndex: 10,
        paddingLeft: 10,
        paddingRight: 10
    },

    text: {
        display: 'flex',
        flex: 1,
        fontFamily:'franklingothic_book',
        fontSize: 32,
        color: 'transparent',
        alignSelf: 'center',
        backgroundColor: 'transparent'
    },

    titleContainer: {
        display: 'flex',
        flex: 3,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },

    textBold: {
        fontFamily:'franklingothic_demi',
        fontSize: 32,
        letterSpacing: -0.8,
        lineHeight: 1,
        color: '#000',
        outline: 'none',
        border: 0,
        underline: 0,
        textDecoration: 'none',
        cursor:'pointer',
    },

    rightSide: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
    },

    linkText: {
        display: 'flex',
        fontFamily:'franklingothic_book',
        color: '#000',
        border: 0,
        underline: 0,
        textDecoration: 'none',
    },

    menu: {
        backgroundColor: 'transparent',
        border: 0,
        marginRight: 30,
        cursor: 'pointer'
    }

}