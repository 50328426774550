import React, { useState, useEffect, createContext } from 'react'
import { Auth } from 'aws-amplify'
// import jwt_decode from "jwt-decode"

const AuthContext = createContext()

export function AuthProvider ({ children }) {
    const [ loadingAuth, setLoadingAuth ] = useState('static')
    const [ userId, setUserId ] = useState("")
    const [ userDetails, setUserDetails ] = useState()
    const [ errorMessage, setErrorMessage ] = useState("")
    const [ userGroups, setUserGroups ] = useState([])
    const [ disablAppContext, setDisabledAppContext ] = useState(false)
    
    // Check if user is logged in on start
    // useEffect(() => {
    //     CheckWhoIsLoggedIn()
    //     CheckUserGroups()
    // }, [])

    // Check if someone is logged in. ----------------------------------------------------------------------------------------------------
    async function CheckWhoIsLoggedIn() {
        try {
            const response = await Auth.currentAuthenticatedUser()

            // Work out what the users groups are.
            await CheckUserGroups()

            setUserDetails(response.attributes)
            setUserId(response.attributes.sub)
            
        } catch (err) {
            setUserId()
        }
    }

    // Log In. ----------------------------------------------------------------------------------------------------
    const LogIn = async ( email, password ) => {
        try {
            setLoadingAuth('loading')

            const response = await Auth.signIn({
                username: email,
                password: password
            })

            // Work out what the users groups are.
            // await CheckUserGroups()

            setUserDetails(response.attributes)
            setUserId(response.attributes.sub)
            setLoadingAuth('loaded')
            
        } catch (err) {
            setErrorMessage(err.code)
            setLoadingAuth('static')
        }
    }

    // Log Out. ----------------------------------------------------------------------------------------------------
    const LogOut = async () => {
        try {
            setLoadingAuth('loading')

            await Auth.signOut()

            setLoadingAuth('loaded')
            setUserId()
            
        } catch (err) {
            setLoadingAuth( 'static' )
        }
    }

    async function CheckUserGroups() {
        try {
            setLoadingAuth('loading')

            // Work out what the users groups are.
            const groups = new Promise((resolve, reject) => {

                Auth.currentAuthenticatedUser()

                // .then((cognitoUser) => {
                //     if (cognitoUser != null) {
                //         cognitoUser.getSession(function (err, session) {
                //             if (err) {
                //                 reject(err)
                //                 return
                //             }
                //             var sessionIdInfo = jwt_decode(session.getIdToken().jwtToken)
                //             resolve(sessionIdInfo["cognito:groups"])
                //         })
                //     }
                // })
                
                // .catch((err) => {
                //     reject(err)
                // })
            })
            
            var currentUserGroups = await groups
            setUserGroups(currentUserGroups)
            setLoadingAuth('loaded')

        } catch (err) {
            console.log('error checking', err)
            setLoadingAuth('static')
        }
    }

    async function ResendConfirmationCode(username) {
        try {
            setLoadingAuth('loading')

            await Auth.resendSignUp(username)

            setLoadingAuth('loaded')

        } catch (err) {
            console.log('error resending code: ', err)
            setLoadingAuth( 'static' )
        }
    }

    // This refreshes the users session (for when the user group type changes)
    async function RefreshSession() {
        try {
            setLoadingAuth('loading')

            const user = await Auth.currentAuthenticatedUser()

            await user.refreshSession(user.signInUserSession.refreshToken, () => {

                Auth.currentUserCredentials().then(() => {

                    Auth.currentAuthenticatedUser()
                    
                    .catch(err => {
                        console.log(err)
                    });

                });

            });

            setLoadingAuth('loaded')

        } catch (err) {
            console.log('error fetching refreshing token:', err)
            setLoadingAuth('static')
        }
    }

    return (
        <AuthContext.Provider value={{
            userId,
            userDetails,
            userGroups,
            loadingAuth,
            setLoadingAuth,
            CheckWhoIsLoggedIn,
            LogIn,
            LogOut,
            // Delete,
            CheckUserGroups,
            ResendConfirmationCode,
            errorMessage,
            setErrorMessage,
            RefreshSession,
            disablAppContext,
            setDisabledAppContext
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;