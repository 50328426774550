import React from 'react'
import { useSpring, animated, config } from '@react-spring/web'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function ArtLoverNine() {
    const [ width, height ] = WindowSize()

    const movement = useSpring({
        from: {opacity: 0, transform: 'translateY(100px)'},
        to: {opacity: 1, transform: 'translateY(0)'},
        config: config.molasses,
        delay: 800
    })

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/c4atTMj.jpg'}
                />

                <animated.div style={Object.assign({}, styles.containerRight, movement, { width: width, height: height })}>

                    <BoldText text={`Experience more`} />

                    <RegularText text={`From our exhibitions and\nHelpful Matrix (our little\nblack book of Art world\ncontacts) to our\nnoticeboard, there’s all\nkinds of ways to explore our\ncommunity and connect\nwith Art and Artists.`} />

                </animated.div>
            </>
        )
  
    // mobile version
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/uIs337X.jpg'}
            />

            <animated.div style={Object.assign({}, styles.containerMobile, movement, { width: '90vw' })}>

                <BoldText text={`Experience more`} />

                <RegularText text={`From our exhibitions and Helpful Matrix (our little black book of Art world contacts) to our noticeboard, there’s all kinds of ways to explore our community and connect with Art and Artists.`} />

            </animated.div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default ArtLoverNine;