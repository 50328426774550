import React from 'react'
import { useLocation } from 'react-router-dom'
// Components.
import SelectTitle from './selectTitle'
import SelectRightSide from './selectRightSide'
// Styling.
import styles from './styles.js'
import WindowSize from '../General/windowsSize'

function Nav() {
    const [ width, height ] = WindowSize()
    let location = useLocation()

    // For now commented out login because we arent launching with that.
    function SelectAuth() {
        return (
            <p style={styles.text}>
                Login
            </p>
        )
    }

    return (
        <nav style={Object.assign({}, styles.container, {
                width: width,
                backgroundColor: '#fff'
        })}>

            <SelectAuth />

            <div style={styles.titleContainer}>
                <SelectTitle />
            </div>

            <div style={styles.rightSide}>
                
                <SelectRightSide />

            </div>

        </nav>
    )
}

export default Nav