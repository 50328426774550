export default {

    container: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '85vw',
        paddingLeft: '10vw',
        paddingRight: '5vw',
        overflow: 'hidden',
        backgroundColor: '#fff'
    },

    containerMobile: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '88vw',
        paddingLeft: '5vw',
        paddingRight: '7vw',
        overflow: 'hidden',
        backgroundColor: '#fff'
    }

}