export default {

    container: {
        paddingTop: '10vh',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    innerContainer : {
        minHeight : "100vh"
    },
    
    content: {
        width: '60vw',
        paddingBottom: 120,
        paddingLeft: '10vw',
        backgroundColor: 'transparent'
    },
    
    contentMobile: {
        width: '90vw',
        paddingBottom: 60,
    },

    videoContainer: {
        // position: 'absolute',
        // zIndex: 2,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        padding: 0,
        border: 0,
        backgroundColor: 'transparent',
    },

}