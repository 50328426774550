import React, { useEffect, useState } from 'react'

export default () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight)
        return () => window.removeEventListener("resize", updateWidthAndHeight)
    })

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
    }

    return [ width, height ]
}