
export default {

    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        margin: 'auto',
        marginLeft: '-10vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent'
    },

    innerContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        alignItems: 'flex-start',
    },

    section: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        padding: 0,
        marginLeft: 0,
        marginRight: '2vw',
        marginBottom: 0,
        marginTop: 0,
        backgroundColor: 'transparent'
    },

    section2: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        marginLeft: '5vw',
        marginRight: 0,
        marginBottom: 0,
        marginTop: 0,
    },

    sectionMobile: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 0,
        marginLeft: '10vw',
        marginRight: '10vw',
        marginBottom: 0,
        marginTop: 0,
        backgroundColor: 'transparent'
    },

    section2Mobile: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        marginLeft: '10vw',
        marginRight: '10vw',
        marginBottom: 0,
        marginTop: 30,
    },

    badgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: '3em'
    },

    name: {
        backgroundColor: 'transparent',
        padding: 0,
        border: 0,
        cursor: 'pointer'
    },

    videoContainer: {
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        backgroundColor: 'rgba(105,105,105, 0.7)',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        width: '100%',
        height: '100%',
        padding: 0,
        border: 0
    },

}