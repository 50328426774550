import React from 'react'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function AboutSix() {
    const [ width, height ] = WindowSize()
  
    return (

        <img
        style={Object.assign({}, styles.imageTwo, { marginLeft: width > Breakpoints.mobile ? '-10vw' : '-5vw' })}
        src={'https://i.imgur.com/Losxdom.jpg'}
        />
        
    )
}

export default AboutSix;