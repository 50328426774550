
export default {

    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent'
    },

    videoContainer: {
        position: 'absolute',
        zIndex: 2,
        display: 'flex',
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        width: '100%',
        height: '100%',
        padding: 0,
        border: 0
    },

    mobileContainer: {
        overflowY: 'scroll',
        overflowX: 'hidden'
    },

    backgroundText: {
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center'
    },

    badgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: '1em'
    },

    badgeContainerMobile: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: '1em'
    },

    muteIcon: {
        position: 'absolute',
        zIndex: 10,
        display: 'flex',
        backgroundColor: 'transparent',
        padding: 0,
        border: 0,
        top: 20,
        left: 20,
        cursor: 'pointer'
    }

}