import React from 'react'
// Components.
import LargerBoldText from '../../Components/Text/LargerBoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'

function Menu() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: '80vw', height: height })}>
            
            <LargerBoldText text="About ›" link='/about' />
            <LargerBoldText text="Manifesto ›" link='/manifesto' />
            <LargerBoldText text="Who Art you? ›" link='/6' />
            <LargerBoldText text="Subscribe ›" link='/subscribe' />
            <LargerBoldText text="App tutorials ›" link='/watchandlearn' />
            <LargerBoldText text="FAQs ›" link='/faqs' />
            <LargerBoldText text="Contact ›" type='email' />

        </div>
    )
}

export default Menu;