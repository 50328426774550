import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function PrivacyPolicy() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>
            
            <div style={styles.innerContainer}>
                
                <BigBoldText text={`Help Ukraine`} />
                <BoldText text={`Fair Art Fair encourages donations to organisations that can help those suffering`} />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                    <button
                    onClick={() => window.open('https://callrussia.org/eng', '_blank')}
                    style={styles.none}
                    >
                        <BoldText text={`\nUkrainian Embassy`} />
                        <RegularText text={`withukraine.org`} />
                    </button>

                    <button
                    onClick={() => window.open('https://www.savethechildren.org.uk/how-you-can-help/emergencies/support-our-emergency-fund?ppc=true&matchtype=&s_keyword=&adposition=&s_kwcid=AL!9048!3!458052851957!!!g!!&gclid=CjwKCAiAyPyQBhB6EiwAFUuaktXYwLsJAGOv3OxF7ECJ0zON4pYEfr8Cfwc_9ONFV75sg73UQBsvWRoCs2kQAvD_BwE&gclsrc=aw.ds', '_blank')}
                    style={styles.none}
                    >
                        <BoldText text={`\nSave the Children’s Emergency Fund`} />
                        <RegularText text={`savethechildren.org.uk/how-you-can-help`} />
                    </button>

                    <button
                    onClick={() => window.open('https://donate.redcross.org.uk/appeal/ukraine-crisis-appeal?utm_campaign=UK%20Solidarity%20Fund&utm_source=Instagram&utm_medium=Social%20Post&utm_content=Donate%20Landing_Instagram_Organic_UkraineAppeal_Donate&utm_term=175155_BRC%20Followers&c_name=UK%20Solidarity%20Fund&c_source=Instagram&c_medium=Social%20Post&c_creative=Donate%20Landing_Instagram_Organic_UkraineAppeal_Donate&c_code=175155&adg=BRC%20Followers', '_blank')}
                    style={styles.none}
                    >
                        <BoldText text={`\nBritish Red Cross Ukraine Crisis Appeal`} />
                        <RegularText text={`donate.redcross.org.uk/appeal/ukraine-crisis-appeal`} />
                    </button>

                    <button
                    onClick={() => window.open('https://donate.chooselove.org/campaigns/ukraine-appeal/', '_blank')}
                    style={styles.none}
                    >
                        <BoldText text={`\nChoose Love Ukraine`} />
                        <RegularText text={`donate.chooselove.org/campaigns/ukraine-appeal/`} />
                    </button>

                    <button
                    onClick={() => window.open('https://www.dec.org.uk/appeal/ukraine-humanitarian-appeal', '_blank')}
                    style={styles.none}
                    >
                        <BoldText text={`\nDisasters Emergency Committee`} />
                        <RegularText text={`dec.org.uk/appeal/ukraine-humanitarian-appeal`} />
                    </button>

                    <button
                    onClick={() => window.open('https://www.msf.org/', '_blank')}
                    style={styles.none}
                    >
                        <BoldText text={`\nMedecin Sans Frontier`} />
                        <RegularText text={`msf.org/`} />
                    </button>

                    <button
                    onClick={() => window.open('https://callrussia.org/eng', '_blank')}
                    style={styles.none}
                    >
                        <BoldText text={`\nCall Russia Russian Speakers`} />
                        <RegularText text={`callrussia.org/eng`} />
                    </button>

                </div>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default PrivacyPolicy;