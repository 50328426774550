import React from 'react'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function CuratorEight() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/sa5EUgC.jpg'}
                />

                {/* <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                    <BoldText text={`Be supported`} />

                    <RegularText text={`Through our portal you can\ninstantly create custom PDFs,\nselections of works and\ndocumentation, and keep\ntrack of your discoveries.\nNeed a trusted framer? A\nreliable shipper? Freelance\ntechnical work? Our Helpful\nMatrix and noticeboard plug\nyou in to all the know-how you\nneed.`} />

                </div> */}
            </>
        )
  
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/sRMOnBi.jpg'}
            />

            {/* <div style={Object.assign({}, styles.containerMobile, { width: '90vw' })}>

                <BoldText text={`Be supported`} />

                <RegularText text={`Through our portal you can instantly create custom PDFs, selections of works and documentation, and keep track of your discoveries. Need a trusted framer? A reliable shipper? Freelance technical work? Our Helpful Matrix and noticeboard plug you in to all the know-how you need.`} />

            </div> */}

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default CuratorEight;