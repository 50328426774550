
export default {

    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#fff'
    },

    titleSection: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: 0,
        marginBottom: 10
    },

    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },

    section: {
        display: 'flex',
        flex: 1,
        marginRight: '2vw'
    },

    section2: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: '2vw',
        padding: 0
    },

    section2Mobile: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
        marginTop: 30,
    },

}