export default {

    image: {
        display: 'flex',
        flex: 1,
        objectFit: 'cover'
    },

    imageTwo: {
        display: 'flex',
        flex: 1,
        height: '100%',
        width: '100vw',
        objectFit: 'cover',
        marginLeft: '-10vw'
    }

}