import React from 'react'
import Vimeo from '@u-wave/react-vimeo'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function WatchAndLearn() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>
                
                <BigBoldText text="Watch and Learn" marginBottom='0.5em' />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                <BoldText text={`Artist profile`} marginTop={40} />
                <RegularText text={`Edit and upload images to your Artist profile`} />
                <div style={styles.videoContainer}>
                    <Vimeo
                    video={"693971464"}
                    autoplay={false}
                    loop={false}
                    width={width > Breakpoints.mobile ? width*0.2 : width*0.9}
                    height={undefined}
                    controls={true}
                    volume={1}
                    />
                </div>

                <BoldText text={`Gender`} marginTop={40} />
                <RegularText text={`Edit your profile and choose your gender`} />
                <div style={styles.videoContainer}>
                    <Vimeo
                    video={"694016637"}
                    autoplay={false}
                    loop={false}
                    width={width > Breakpoints.mobile ? width*0.2 : width*0.9}
                    height={undefined}
                    controls={true}
                    volume={1}
                    />
                </div>

                <BoldText text={`Works`} marginTop={40} />
                <RegularText text={`Create and edit artworks in your inventory`} />
                <div style={styles.videoContainer}>
                    <Vimeo
                    video={"693971528"}
                    autoplay={false}
                    loop={false}
                    width={width > Breakpoints.mobile ? width*0.2 : width*0.9}
                    height={undefined}
                    controls={true}
                    volume={1}
                    />
                </div>

                <BoldText text={`Albums`} marginTop={40} />
                <RegularText text={`Create and add to albums`} />
                <div style={styles.videoContainer}>
                    <Vimeo
                    video={"693970196"}
                    autoplay={false}
                    loop={false}
                    width={width > Breakpoints.mobile ? width*0.2 : width*0.9}
                    height={undefined}
                    controls={true}
                    volume={1}
                    />
                </div>

                <BoldText text={`PDFs`} marginTop={40} />
                <RegularText text={`Create and share pdfs`} />
                <div style={styles.videoContainer}>
                    <Vimeo
                    video={"693970249"}
                    autoplay={false}
                    loop={false}
                    width={width > Breakpoints.mobile ? width*0.2 : width*0.9}
                    height={undefined}
                    controls={true}
                    volume={1}
                    />
                </div>

                </div>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default WatchAndLearn;