import React, { useState, useEffect, useContext } from 'react'
import { BrowserRouter, Route, useLocation, Redirect, Switch } from 'react-router-dom'
import { CSSTransition } from "react-transition-group"
import ReactPixel from 'react-facebook-pixel'
import Cookies from 'js-cookie'
// Components.
import Nav from './Components/Nav'
import ScrollIndicator from './Components/ScrollIndicator'
import Content from './AppContent'
import HoverSubscribe from './Components/HoverSubscribe'
import ManageGdpr from './Components/ManageGdpr'
import ManageGdprMobile from './Components/ManageGdpr/indexMobile'
import CookiesBanner from './Components/CookiesBanner'
import CookiesBannerMobile from './Components/CookiesBanner/indexMobile'
// Screens.
import HomeOne from './Screens/Home/one'
import HomeTwo from './Screens/Home/two'
import HomeThree from './Screens/Home/three'
import HomeFour from './Screens/Home/four'
import HomeFive from './Screens/Home/five'
import HomeSix from './Screens/Home/six'
import HomeSeven from './Screens/Home/seven'
import HomeEight from './Screens/Home/eight'
import HomeNine from './Screens/Home/nine'
import HomeTen from './Screens/Home/ten'

import ArtCuriousJourneyOne from './Screens/ArtCuriousJourney/one'
import ArtCuriousJourneyTwo from './Screens/ArtCuriousJourney/two'

import ArtistJourneyOne from './Screens/ArtistJourney/one'
import ArtistJourneyTwo from './Screens/ArtistJourney/two'
import ArtistJourneyThree from './Screens/ArtistJourney/three'
import ArtistJourneyFour from './Screens/ArtistJourney/four'
import ArtistJourneyFive from './Screens/ArtistJourney/five'
import ArtistJourneySix from './Screens/ArtistJourney/six'
import ArtistJourneySeven from './Screens/ArtistJourney/seven'
import ArtistJourneyEight from './Screens/ArtistJourney/eight'
import ArtistJourneyNine from './Screens/ArtistJourney/nine'

import ArtLoverJourneyOne from './Screens/ArtLoverJourney/one'
import ArtLoverJourneyTwo from './Screens/ArtLoverJourney/two'
import ArtLoverJourneyThree from './Screens/ArtLoverJourney/three'
import ArtLoverJourneyFour from './Screens/ArtLoverJourney/four'
import ArtLoverJourneyFive from './Screens/ArtLoverJourney/five'
import ArtLoverJourneySix from './Screens/ArtLoverJourney/six'
import ArtLoverJourneySeven from './Screens/ArtLoverJourney/seven'
import ArtLoverJourneyEight from './Screens/ArtLoverJourney/eight'
import ArtLoverJourneyNine from './Screens/ArtLoverJourney/nine'

import CuratorJourneyOne from './Screens/CuratorJourney/one'
import CuratorJourneyTwo from './Screens/CuratorJourney/two'
import CuratorJourneyThree from './Screens/CuratorJourney/three'
import CuratorJourneyFour from './Screens/CuratorJourney/four'
import CuratorJourneyFive from './Screens/CuratorJourney/five'
import CuratorJourneySix from './Screens/CuratorJourney/six'
import CuratorJourneySeven from './Screens/CuratorJourney/seven'
import CuratorJourneyEight from './Screens/CuratorJourney/eight'

import AboutOne from './Screens/About/one'
import AboutTwo from './Screens/About/two'
import AboutThree from './Screens/About/three'
import AboutFour from './Screens/About/four'
import AboutFive from './Screens/About/five'
import AboutSix from './Screens/About/six'
import AboutSeven from './Screens/About/seven'
import AboutEight from './Screens/About/eight'
import AboutNine from './Screens/About/nine'
import AboutTen from './Screens/About/ten'
import AboutEleven from './Screens/About/eleven'
import AboutTwelve from './Screens/About/twelve'

import Menu from './Screens/Menu'
import Faqs from './Screens/Faqs'
import TermsConditions from './Screens/TermsConditions'
import PrivacyPolicy from './Screens/PrivacyPolicy'
import SubscriptionInformation from './Screens/SubscriptionInformation'
import Manifesto from './Screens/Manifesto'
import Subscribe from './Screens/Subscribe'
import WatchAndLearn from './Screens/WatchAndLearn'
import HelpUkraine from './Screens/HelpUkraine'

import LogIn from './Screens/LogIn'

import SubscriberCount from './Screens/SubscriberCount'
import AppChangeLog from './Screens/AppChangeLog'

import NoMatch from './Screens/NoMatch'
// Context.
import AuthContext from './Context/authContext'
import GeneralContext from './Context/generalContext'
//Styling.
import WindowSize from './Components/General/windowsSize'
import Breakpoints from './Components/General/breakpoints'
import './styles.css'

// Facebook pixel.
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
}

const routes = [
    { path: '/', Component: HomeOne, forward: '/2', back: 'null' },
    { path: '/2', Component: HomeTwo, forward: '/3', back: '/' },
    { path: '/3', Component: HomeThree, forward: '/4', back: '/2' },
    { path: '/4', Component: HomeFour, forward: '/5', back: '/3' },
    { path: '/5', Component: HomeFive, forward: '/6', back: '/4' },
    { path: '/6', Component: HomeSix, forward: '/7', back: '/5' },
    { path: '/7', Component: HomeSeven, forward: '/8', back: '/6' },
    { path: '/8', Component: HomeEight, forward: '/9', back: '/7' },
    { path: '/9', Component: HomeNine, forward: '/10', back: '/8' },
    { path: '/10', Component: HomeTen, forward: '/null', back: '/9' },

    { path: '/artcuriousjourney', Component: ArtCuriousJourneyOne, forward: '/artcuriousjourney/2', back: 'null' },
    { path: '/artcuriousjourney/2', Component: ArtCuriousJourneyTwo, forward: '/8', back: '/artcuriousjourney' },

    { path: '/artistjourney', Component: ArtistJourneyOne, forward: '/artistjourney/2', back: 'null' },
    { path: '/artistjourney/2', Component: ArtistJourneyTwo, forward: '/artistjourney/3', back: '/artistjourney' },
    { path: '/artistjourney/3', Component: ArtistJourneyThree, forward: '/artistjourney/4', back: '/artistjourney/2' },
    { path: '/artistjourney/4', Component: ArtistJourneyFour, forward: '/artistjourney/5', back: '/artistjourney/3' },
    { path: '/artistjourney/5', Component: ArtistJourneyFive, forward: '/artistjourney/6', back: '/artistjourney/4' },
    { path: '/artistjourney/6', Component: ArtistJourneySix, forward: '/artistjourney/7', back: '/artistjourney/5' },
    { path: '/artistjourney/7', Component: ArtistJourneySeven, forward: '/artistjourney/8', back: '/artistjourney/6' },
    { path: '/artistjourney/8', Component: ArtistJourneyEight, forward: '/artistjourney/9', back: '/artistjourney/7' },
    { path: '/artistjourney/9', Component: ArtistJourneyNine, forward: '/8', back: '/artistjourney/8' },

    { path: '/artloverjourney', Component: ArtLoverJourneyOne, forward: '/artloverjourney/2', back: 'null' },
    { path: '/artloverjourney/2', Component: ArtLoverJourneyTwo, forward: '/artloverjourney/3', back: '/artloverjourney' },
    { path: '/artloverjourney/3', Component: ArtLoverJourneyThree, forward: '/artloverjourney/4', back: '/artloverjourney/2' },
    { path: '/artloverjourney/4', Component: ArtLoverJourneyFour, forward: '/artloverjourney/5', back: '/artloverjourney/3' },
    { path: '/artloverjourney/5', Component: ArtLoverJourneyFive, forward: '/artloverjourney/6', back: '/artloverjourney/4' },
    { path: '/artloverjourney/6', Component: ArtLoverJourneySix, forward: '/artloverjourney/7', back: '/artloverjourney/5' },
    { path: '/artloverjourney/7', Component: ArtLoverJourneySeven, forward: '/artloverjourney/8', back: '/artloverjourney/6' },
    { path: '/artloverjourney/8', Component: ArtLoverJourneyEight, forward: '/artloverjourney/9', back: '/artloverjourney/7' },
    { path: '/artloverjourney/9', Component: ArtLoverJourneyNine, forward: '/8', back: '/artloverjourney/8' },

    { path: '/curatorjourney', Component: CuratorJourneyOne, forward: '/curatorjourney/2', back: 'null' },
    { path: '/curatorjourney/2', Component: CuratorJourneyTwo, forward: '/curatorjourney/3', back: '/curatorjourney' },
    { path: '/curatorjourney/3', Component: CuratorJourneyThree, forward: '/curatorjourney/4', back: '/curatorjourney/2' },
    { path: '/curatorjourney/4', Component: CuratorJourneyFour, forward: '/curatorjourney/5', back: '/curatorjourney/3' },
    { path: '/curatorjourney/5', Component: CuratorJourneyFive, forward: '/curatorjourney/6', back: '/curatorjourney/4' },
    { path: '/curatorjourney/6', Component: CuratorJourneySix, forward: '/curatorjourney/7', back: '/curatorjourney/5' },
    { path: '/curatorjourney/7', Component: CuratorJourneySeven, forward: '/curatorjourney/8', back: '/curatorjourney/6' },
    { path: '/curatorjourney/8', Component: CuratorJourneyEight, forward: '/8', back: '/curatorjourney/7' },

    { path: '/about', Component: AboutOne, forward: '/about/2', back: 'null' },
    { path: '/about/2', Component: AboutTwo, forward: '/about/3', back: '/about' },
    { path: '/about/3', Component: AboutThree, forward: '/about/4', back: '/about/2' },
    { path: '/about/4', Component: AboutFour, forward: '/about/5', back: '/about/3' },
    { path: '/about/5', Component: AboutFive, forward: '/about/6', back: '/about/4' },
    { path: '/about/6', Component: AboutSix, forward: '/about/7', back: '/about/5' },
    { path: '/about/7', Component: AboutSeven, forward: '/about/8', back: '/about/6' },
    { path: '/about/8', Component: AboutEight, forward: '/about/9', back: '/about/7' },
    { path: '/about/9', Component: AboutNine, forward: '/about/10', back: '/about/8' },
    { path: '/about/10', Component: AboutTen, forward: '/about/11', back: '/about/9' },
    { path: '/about/11', Component: AboutEleven, forward: '/about/12', back: '/about/10' },
    { path: '/about/12', Component: AboutTwelve, forward: '/menu', back: '/about/11' },

    { path: '/menu', Component: Menu, forward: 'null', back: 'null' },
    { path: '/faqs', Component: Faqs, forward: 'null', back: 'null' },
    { path: '/termsandconditions', Component: TermsConditions, forward: 'null', back: 'null' },
    { path: '/privacypolicy', Component: PrivacyPolicy, forward: 'null', back: 'null' },
    { path: '/subscriptioninformation', Component: SubscriptionInformation, forward: 'null', back: 'null' },
    { path: '/manifesto', Component: Manifesto, forward: 'null', back: 'null' },
    { path: '/subscribe', Component: Subscribe, forward: 'null', back: 'null' },
    { path: '/watchandlearn', Component: WatchAndLearn, forward: 'null', back: 'null' },
    { path: '/help-ukraine', Component: HelpUkraine, forward: 'null', back: 'null' },

    { path: '/login', Component: LogIn, forward: 'null', back: 'null' },

    { path: '/subscribercount', Component: SubscriberCount, forward: 'null', back: 'null' },
    { path: '/app-changelog', Component: AppChangeLog, forward: 'null', back: 'null' },
  ]

function App() {
    const [ width, height ] = WindowSize()

    // because i cant use a normal timer because the setState causes the whole thing to rerender, instead its just a basic time check to see if enough time
    // has passed since the last scroll
    const [ disabled, setDisabled ] = useState()
    // This manually hide the cookies banner after use selects yes or no.
    const [ showCookiesBanner, setShowCookiesBanner ] = useState(false)
    const [regularFontLoaded, setRegularFontLoaded] = useState(false)
    const [boldFontLoaded, setBoldFontLoaded] = useState(false)

    const { userId, RefreshSession, CheckUserGroups, userGroups, loadingAuth } = useContext(AuthContext)
    const { showManageGdpr } = useContext(GeneralContext)

    // this lets the function know when the font is loaded, to avoid brief font type wrongly showing
    useEffect(() => {
        document.fonts.load("12px franklingothic_book").then(() => setRegularFontLoaded(true))
        document.fonts.load("12px franklingothic_demi").then(() => setBoldFontLoaded(true))
        CheckCookies()
    }, [])

    // only allow the scroll to work after 1600ms (purposefully a little longer than the transition to ensure no page skipping).
    useEffect(() => {
        const timer = setTimeout(() => {
            setDisabled(false)
        }, 1600)
        return () => clearTimeout(timer)
    }, [disabled])

    // Only show the cookies banner when the user hasnt made a choice.
    function CheckCookies() {
        let response = Cookies.get('AllowMarketing')

        !response ? ReactPixel.revokeConsent() : ReactPixel.init('299451758310702', options)

        setShowCookiesBanner( response === undefined ? true : false )
    }

    function NavBar() {
        let location = useLocation()

        return (
            <>
                {
                    location.pathname === '/' ||
                    location.pathname === '/3' ||
                    location.pathname === '/4' ||
                    location.pathname === '/5' ||
                    location.pathname === '/7' ||
                    location.pathname === '/8' ||
                    location.pathname === '/9' ||
                    location.pathname === '/about/2' ||
                    location.pathname === '/about/4' ||
                    location.pathname === '/about/6' ||
                    location.pathname === '/about/7' ||
                    location.pathname === '/about/8' ||
                    location.pathname === '/about/10' ||
                    location.pathname === '/about/11' ||
                    location.pathname === '/about/12' ?
                        null
                    :
                        <Nav />
                }


                {/* the !! makes it return a boolen value. this checks whether the current url matches any in our array of urls above, if it doesnt we know the user is lost */}
                {!!routes.find(item => item.path === location.pathname) === true ? null : <NoMatch />}
            </>
        )
    }

    {/* managing the hover button on screen /12 manually there to avoid it overlapping the footer */}
    function HoverSubscribeButton() {
        let location = useLocation()

        if(location.pathname === '/login' ||
            location.pathname === '/subscribe' ||
            location.pathname === '/subscribercount' ||
            location.pathname === '/menu' ||
            location.pathname === '/termsandconditions' ||
            location.pathname === '/2' ||
            location.pathname === '/6' ||
            location.pathname === '/7'||
            location.pathname === '/10' ||
            location.pathname === '/help-ukraine'
        )
            return null

        return <HoverSubscribe />
    }

    return (
        <BrowserRouter>

            <ScrollIndicator />

            <NavBar />

            {/* only display pages once the font is loaded */}
            {
                regularFontLoaded === false || boldFontLoaded === false ?
                    null
                :
                    <>
                        {routes.map(({ path, Component, forward, back }) => (

                            // If the user is not logged in and tries to access these pages, force them to log in
                            // !userId && path === '/subscribercount' ?
                            //     <Redirect
                            //     to={{ pathname: "/login" }}
                            //     />
                            // :
                                <Route key={path} exact path={path}>

                                {({ match }) => (

                                    <CSSTransition
                                    in={match != null}
                                    timeout={1500}
                                    classNames="page"
                                    unmountOnExit
                                    >

                                        <div className="page">
                                            <Content path={path} Component={Component} forward={forward} back={back} disabled={disabled} setDisabled={setDisabled} />
                                        </div>

                                    </CSSTransition>

                                )}

                            </Route>

                        ))}
                    </>
            }
            
            <HoverSubscribeButton />

            {
                showManageGdpr ?
                    width > Breakpoints.mobile ? <ManageGdpr setShowCookiesBanner={setShowCookiesBanner} /> : <ManageGdprMobile setShowCookiesBanner={setShowCookiesBanner} />
                :
                    null
            }

            {
                showCookiesBanner ?
                    width > Breakpoints.mobile ? <CookiesBanner setShowCookiesBanner={setShowCookiesBanner} /> : <CookiesBannerMobile setShowCookiesBanner={setShowCookiesBanner} />
                :
                    null
            }

        </BrowserRouter>
    )
}

export default App;
