import React from 'react'
import { Link } from 'react-router-dom'
// Styling.
import styles from './styles'
import WindowSize from '../General/windowsSize'
import Breakpoints from '../General/breakpoints'

function HoverSubscribe() {
    const [ width, height ] = WindowSize()

    return (
        <Link
        to='/subscribe'
        style={Object.assign({}, styles.container, {
            height: width/11 < 100 ? 100 : width/11 > 150 ? 150 : width/11,
            width: width/11 < 100 ? 100 : width/11 > 150 ? 150 : width/11,
            right: width/34 < 10 ? 10 : width/34 > 50 ? 50 : width/34,
            bottom: width/34 < 10 ? 10 : width/34 > 50 ? 50 : width/34,
        })}
        >
            <p
            style={{
                fontFamily:'franklingothic_demi',
                fontSize: width/57 < 18 ? 18 : width/57 > 25 ? 25 : width/63,
                color: '#fff',
                letterSpacing: -0.3,
                lineHeight: 1.1,
                marginTop: 10,
                marginBottom: 0,
                padding: 0,
                textAlign: 'center',
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                cursor: 'pointer'
            }}>
                {`Download\nthe app\nnow`}
            </p>

        </Link>
    )
}

export default HoverSubscribe;