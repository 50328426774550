import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Vimeo from '@u-wave/react-vimeo'
import ReactPixel from 'react-facebook-pixel'
// Styling.
import styles from './styles'
import WindowsSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'
import '../../../styles.css'

function HomeOne() {
    let history = useHistory()
    const [ width, height ] = WindowsSize()
    const [ showVideo, setShowVideo ] = useState(false)
    const [ videoLoaded, setVideoLoaded ] = useState(false)
    const [ muted, setMuted ] = useState(true)

    console.log(muted)

    useEffect(() => {
        ReactPixel.pageView()
    }, [])

    // this waiting 50ms before setting the updated newWidth and newHeight, we then check if newWidth === width etc and when they do we
    // know the window is no longer being resized. This means that the video will stop rendering whenever
    // the window is resized and rerender when it stops being resized, so we avoid the resize bug.
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowVideo(true)
        }, 50)
        return () => clearTimeout(timer)
    }, [width, height])
  
    return (

        <div style={Object.assign({}, styles.container, { width: width, height: height, marginLeft: width > Breakpoints.mobile ? '-10vw' : '0vw' })}>

            

            {/* This is in the background to show whenever the video is loading. */}
            {
                !videoLoaded ?
                    <div style={Object.assign({}, styles.backgroundText, { width: width, height: height })}>
                        <svg
                        viewBox="0 0 423.94 72.3"
                        fill={'#000'}
                        height={width/17 < 35 ? 35 : width/17 > 100 ? 100 : width/17}
                        >
                            <path d="M219.11,35.78l20.17,70.82H223.46l-3.61-14.55H200l-3.5,14.55H182.16L203.4,35.78ZM217,79.84,209.87,51,203,79.84Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M255,54.26V66.89c1.28-4.46,3.82-13.38,12.32-13.38a8.9,8.9,0,0,1,2.55.32V68.06a13.1,13.1,0,0,0-2.45-.21c-10.72,0-10.72,12.1-10.72,16.13V106.6H242.17V54.26Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M291.68,37.69V54.26h9.66V65.72h-9.66V84.94c0,9.87,0,11.15,10.41,10.51V106.6a43.17,43.17,0,0,1-9,1.06c-4.78,0-10.08-1.17-13.06-5.41-2.54-3.72-2.54-9-2.54-15.72V65.72h-5.24V54.26h6l1.17-15.4Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M361.12,35.78V48H334.68V66.47h20.38V78.68H334.68V106.6h-15.5V35.78Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M360.58,69.44c.43-3,1.28-9.24,8.71-13.49a24.94,24.94,0,0,1,12.42-2.86c5.52,0,11.26,1.38,15.18,5.52,3.19,3.18,3.4,9.13,3.4,15.5V94.92c0,8.92.32,9.87.85,11.68H387.66c-.64-2.13-.75-3.29-.75-8.07-1.91,3.4-5.3,9.13-14.43,9.13-7,0-13.7-4-13.7-14.33,0-14.55,11.36-18.37,27-19.22v-2c0-4.56,0-9.34-5.21-9.34-4.88,0-5.84,3.93-6.26,8.07Zm25.17,12.1c-8.39,1.06-11.89,3.08-11.89,9.88,0,3.71,1.38,6.58,4.88,6.58a5.65,5.65,0,0,0,4.78-2.87c2-3,2.23-6.26,2.23-11Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M408.82,49.8V35.36h14.75V49.8Zm14.65,4.46V106.6H408.92V54.26Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M445,54.26V66.89c1.27-4.46,3.82-13.38,12.31-13.38a8.85,8.85,0,0,1,2.55.32V68.06a13,13,0,0,0-2.44-.21c-10.72,0-10.72,12.1-10.72,16.13V106.6H432.12V54.26Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M77.82,35.78V48H51.39V66.47H71.77V78.68H51.39V106.6H35.89V35.78Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M77.29,69.44c.43-3,1.28-9.24,8.71-13.49a24.92,24.92,0,0,1,12.42-2.86c5.52,0,11.25,1.38,15.18,5.52,3.19,3.18,3.4,9.13,3.4,15.5V94.92c0,8.92.32,9.87.85,11.68H104.36c-.63-2.13-.74-3.29-.74-8.07-1.91,3.4-5.31,9.13-14.44,9.13-7,0-13.69-4-13.69-14.33,0-14.55,11.36-18.37,27-19.22v-2c0-4.56,0-9.34-5.2-9.34-4.88,0-5.84,3.93-6.26,8.07Zm25.16,12.1c-8.38,1.06-11.89,3.08-11.89,9.88,0,3.71,1.38,6.58,4.89,6.58,1.8,0,3.39-.85,4.77-2.87,2-3,2.23-6.26,2.23-11Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M125.52,49.8V35.36h14.76V49.8Zm14.65,4.46V106.6H125.63V54.26Z" transform="translate(-35.89 -35.36)"/>
                            <path d="M161.67,54.26V66.89C163,62.43,165.5,53.51,174,53.51a8.9,8.9,0,0,1,2.55.32V68.06a13.07,13.07,0,0,0-2.44-.21c-10.73,0-10.73,12.1-10.73,16.13V106.6H148.83V54.26Z" transform="translate(-35.89 -35.36)"/>
                        </svg>
                    </div>
                :
                    null
            }
            
            {
                !showVideo ?
                    null
                :
                    <>
                        <div
                        // className={"container"}
                        style={styles.videoContainer}
                        >

                            <Vimeo
                            video={width > Breakpoints.mobile ? "665955051" : "665950385"}
                            autoplay={true}
                            loop={true}
                            width={undefined}
                            height={height}
                            controls={false}
                            onLoaded={() => setVideoLoaded(true)}
                            muted={muted}
                            volume={muted ? 0 : 1}
                            // randomise the video start time.
                            // start={Math.floor(Math.random()*(90-1))}
                            />

                        </div>

                        <button
                        style={styles.muteIcon}
                        onClick={() => setMuted(!muted)}
                        >
                            {
                                muted ?
                                    <svg
                                    fill={'#000'}
                                    height={'2em'}
                                    width={'2em'}
                                    viewBox="0 0 23 22"
                                    >
                                        <path d="M22.134,10.1c-0.003,-5.141 -4.231,-9.371 -9.372,-9.376l0,3l0.009,0c3.491,0 6.363,2.872 6.363,6.363c0,1.825 -0.785,3.565 -2.154,4.772l-1.166,-1.166c1.069,-0.896 1.688,-2.223 1.688,-3.618c0,-2.59 -2.131,-4.721 -4.721,-4.721l-0.019,0l0,3l0.003,0c0.953,0 1.738,0.785 1.738,1.738c0,0.598 -0.309,1.156 -0.816,1.473l-2.263,-2.265l0,-7.7l-5.042,2.66l-4.261,-4.26l-2.121,2.121l3.095,3.1l-2.461,0l0,9.76l3.934,0l6.856,3.611l0,-5.047l2.758,2.755c-0.466,0.109 -0.942,0.164 -1.42,0.166l0,3c1.302,0.001 2.591,-0.272 3.781,-0.8l3.26,3.26l2.121,-2.126l-2.823,-2.824c1.928,-1.765 3.029,-4.262 3.033,-6.876m-13.71,3.521l-3.114,-1.64l-1.676,-0l0,-3.765l1.676,-0l0.514,-0.271l2.6,2.6l0,3.076Z" />
                                    </svg>
                                :
                                    <svg
                                    fill={'#000'}
                                    height={'2em'}
                                    width={'2em'}
                                    viewBox="0 0 22 19"
                                    >
                                        <path d="M10.791,17.865l-6.856,-3.61l-3.935,-0l-0,-9.764l3.935,-0l6.856,-3.61l0,16.984Zm-7.791,-6.612l1.677,-0l3.114,1.64l0,-7.042l-3.114,1.64l-1.677,-0l0,3.762Zm13.87,-1.881c-0.003,-2.6 -2.141,-4.738 -4.741,-4.741l0,3c0.955,-0 1.741,0.786 1.741,1.741c0,0.955 -0.786,1.741 -1.741,1.741c0,-0 0,3 0,3c2.6,-0.003 4.738,-2.141 4.741,-4.741m4.63,-0c-0.005,-5.139 -4.232,-9.366 -9.371,-9.372l0,3c3.496,-0 6.372,2.876 6.372,6.372c0,3.496 -2.876,6.372 -6.372,6.372l0,3c5.139,-0.006 9.366,-4.233 9.371,-9.372" />
                                    </svg>
                            }
                        </button>
                    </>
            }

            <Link to={() => '/2'} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                position: 'absolute',
                width: width,
                height: height*0.83,
                bottom: 0,
                paddingBottom: 30,
                zIndex: 4,
                textDecoration: 'none',
                backgroundColor: 'transparent'
            }}>
                <svg
                fill={'#000'}
                height={'1em'}
                width={'2em'}
                viewBox="0 0 27.443 12.238"
                >
                    <g transform="translate(-684.424 -733.13)">
                        <path d="M711.867,737.3l-13.722,8.066L684.424,737.3V733.13l13.721,4.265,13.722-4.265Z"/>
                    </g>
                </svg>
            </Link>
            
            <div
            style={Object.assign({}, styles.container, { width: width, height: height, zIndex: 3, position: 'absolute' })}
            // for whatever reason the video stops the app.js scroll detection working so gotta apply it here instead.
            onWheel={ event => event.nativeEvent.wheelDelta > 0 ? console.log(null) : history.push('/2') }
            />

        </div>
    )
}

export default HomeOne;