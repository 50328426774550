
export default {

    container: {
        paddingTop: '10vh',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        overflowX: 'hidden',
        marginLeft: '-5vw'
    },

    innerContainer: {
        minHeight : "100vh",
        width: '80vw',
        marginLeft: '5vw'
    },

    promotionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 40
    },

    image: {
        height: 120,
        marginLeft: 20
    },

    verticalSection: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },

    badge: {
        height: 60
    }

}