import React, { useContext } from 'react'
import ReactPixel from 'react-facebook-pixel'
import Cookies from 'js-cookie'
// Components.
import SmallBoldText from '../Text/SmallBoldText'
import SmallRegularText from '../Text/SmallRegularText'
// Context.
import GeneralContext from '../../Context/generalContext'
// Styling.
import styles from './stylesMobile'
import WindowSize from '../General/windowsSize'

function CookiesBannerMobile({ setShowCookiesBanner }) {
    const { setShowManageGdpr } = useContext(GeneralContext)
      
    return (
           
        <div style={styles.container}>

            <div style={{display: 'flex', flex: 3, flexDirection: 'column', paddingLeft: '5vw'}}>
                <SmallBoldText text={`Cookies & Anonymous Information use:`}/>
                <SmallRegularText text={`We measure audience engagement and site statistics to understand how our services are used  to improve the quality of our services and develop new ones and deliver and measure the effectiveness of ads. Opt in here to help us improve your experience.`}/>
            </div>

            <div style={{display: 'flex', flex: 1, flexDirection: 'column', paddingRight: '5vw', marginLeft: 30}}>
                
                <div
                style={styles.leftButton}
                onClick={() => setShowManageGdpr(true)}
                >
                    <SmallRegularText text="Settings" cen cursor='pointer' />
                </div>

                <div
                style={styles.rightButton}
                onClick={() => ( ReactPixel.grantConsent(), setShowCookiesBanner(false), Cookies.set('AllowMarketing', true) )}
                >
                    <SmallRegularText text="Accept" color='#fff' cursor='pointer' />
                </div>

            </div>
            
        </div>
        
    )
}

export default CookiesBannerMobile;