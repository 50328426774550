import React from 'react'
import { Link } from 'react-router-dom'
// Styling.
import WindowSize from '../General/windowsSize'

function BigBoldText({ text, link, marginTop }) {
    const [ width, height ] = WindowSize()

    return (
        <Link to={link} style={{textDecoration: 'none'}}>
            <h1
            style={{
                fontFamily:'franklingothic_book',
                fontSize: width/12 < 60 ? 60 : width/12 > 200 ? 200 : width/12,
                color: '#000',
                letterSpacing: -2,
                lineHeight: 1,
                marginTop: !marginTop ? 0 : marginTop,
                marginBottom: 0,
                padding: 0,
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                cursor: link === undefined ? 'default' : 'pointer'
            }}
            >
                {text}
            </h1>
        </Link>
    )
}

export default BigBoldText;