
export default {

    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        margin: 'auto',
        marginLeft: '-10vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
    },

    innerContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start',
    },

    section: {
        display: 'flex',
        flexDirection:' column',
        flex: 1,
        alignItems: 'flex-end',
        marginRight: '2.5vw',
    },

    section2: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: '2.5vw'
    },

    sectionMobile: {
        display: 'flex',
        flex: 1,
        alignItems: 'flex-end',
        padding: 0,
        marginLeft: '10vw'
    },

    section2Mobile: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: '10vw',
        marginTop: 30,
    }

}