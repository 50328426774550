export default {

    container: {
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        backgroundColor: '#000',
        borderRadius: 100,
        zIndex: 10,
        cursor:'pointer',
        outline: 'none',
        border: 0,
        textDecoration: 'none'
    }

}