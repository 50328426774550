import React from 'react'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function ArtLoverSix() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/w9nLvrw.jpg'}
                />

                <div style={Object.assign({}, styles.containerRight, { width: width, height: height })}>

                    <BoldText text={`Support artists directly`} />

                    <RegularText text={`You can connect directly to\nArtists and their work – no\nmiddleman and no\ncommission.`} />

                </div>
            </>
        )
  
    // mobile version
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/NEbMx7M.jpg'}
            />

            <div style={Object.assign({}, styles.containerMobile, { width: '90vw' })}>

                <BoldText text={`Support artists directly`} />

                <RegularText text={`You can connect directly to Artists and their work – no middleman and no commission.`} />

            </div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default ArtLoverSix;