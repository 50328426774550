import React from 'react'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function HomeNine() {
    const [ width, height ] = WindowSize()
  
    return (

        <img
        style={Object.assign({}, styles.image, {
            marginLeft: width > Breakpoints.mobile ? '-10vw' : '-5vw',
            width: width,
            height: height
        })}
        src={'https://i.imgur.com/AXeSPQI.jpg'}
        />
        
    )
}

export default HomeNine;