import React, { useContext } from 'react'
// Components.
import RegularText from '../Text/RegularText'
import BoldText from '../Text/BoldText'
import SmallRegularText from '../Text/SmallRegularText'
// Context.
import GeneralContext from '../../Context/generalContext'
// Styling.
import styles from './styles'
import WindowSize from '../General/windowsSize'
import Breakpoints from '../General/breakpoints'
// Assets.
import Unit1Logo from '../../Assets/unit1Logo.png'
import ArtCouncilEnglandLogo from '../../Assets/artCouncilEnglandLogo.png'

function Footer() {
    const [ width, height ] = WindowSize()
    const { setShowManageGdpr } = useContext(GeneralContext)
  
    return (
           
        <>
            {/* Line */}
            <div style={styles.line} />
                
            <div style={Object.assign({}, styles.container, { width: width })}>

                {/* Top section */}
                <div style={Object.assign({}, styles.section, { width: width > Breakpoints.tablet ? width*0.6 : width*0.8 })}>

                    <div style={styles.sectionLeft}>

                        <BoldText text={`FairArtFair`} />
                        <div style={{width: 1, height: 10}} />
                        <RegularText text={`A new world of Art`} />
                        <SmallRegularText text={`Fair Art Fair facilitates relationships between everyone interested in Art in\na confident transparent environment. Whoever you Art you will find a place\nto explore and discover, buy and sell, organise and document,\ncommunicate and be supported.`} />

                        <div style={styles.row}>

                            {/* Instagram icon */}
                            <svg
                            style={{cursor:'pointer'}}
                            onClick={() => window.open('http://instagram.com/fairartfair.art', '_blank')}
                            width="32" height="32" viewBox="0 0 32 32"
                            >
                                <g transform="translate(-244.287 -633.385)">
                                    <path d="M268.393,643.252a3.446,3.446,0,0,0-1.973-1.974,5.736,5.736,0,0,0-1.931-.358c-1.1-.05-1.426-.061-4.2-.061s-3.106.011-4.2.061a5.73,5.73,0,0,0-1.931.358,3.444,3.444,0,0,0-1.974,1.974,5.753,5.753,0,0,0-.358,1.93c-.05,1.1-.061,1.426-.061,4.2s.011,3.1.061,4.2a5.761,5.761,0,0,0,.358,1.931,3.438,3.438,0,0,0,1.974,1.973,5.75,5.75,0,0,0,1.931.359c1.1.05,1.425.06,4.2.06s3.106-.01,4.2-.06a5.756,5.756,0,0,0,1.931-.359,3.448,3.448,0,0,0,1.973-1.973,5.755,5.755,0,0,0,.359-1.931c.05-1.1.06-1.426.06-4.2s-.01-3.106-.06-4.2A5.747,5.747,0,0,0,268.393,643.252Zm-8.106,11.473a5.34,5.34,0,1,1,5.34-5.34A5.34,5.34,0,0,1,260.287,654.725Zm5.551-9.644a1.247,1.247,0,1,1,1.248-1.247A1.247,1.247,0,0,1,265.838,645.081Z"/>
                                    <circle cx="3.466" cy="3.466" r="3.466" transform="translate(256.82 645.918)"/>
                                    <path d="M260.287,633.385a16,16,0,1,0,16,16A16,16,0,0,0,260.287,633.385Zm10.336,20.287a7.63,7.63,0,0,1-.483,2.525,5.32,5.32,0,0,1-3.041,3.041,7.629,7.629,0,0,1-2.525.483c-1.109.051-1.463.063-4.287.063s-3.179-.012-4.288-.063a7.617,7.617,0,0,1-2.524-.483,5.317,5.317,0,0,1-3.042-3.041,7.656,7.656,0,0,1-.483-2.525c-.051-1.109-.062-1.463-.062-4.287s.011-3.179.062-4.288a7.643,7.643,0,0,1,.483-2.524,5.323,5.323,0,0,1,3.042-3.042,7.643,7.643,0,0,1,2.524-.483c1.109-.051,1.463-.063,4.288-.063s3.178.012,4.287.063a7.655,7.655,0,0,1,2.525.483,5.325,5.325,0,0,1,3.041,3.042,7.618,7.618,0,0,1,.483,2.524c.051,1.109.063,1.463.063,4.288S270.674,652.563,270.623,653.672Z"/>
                                </g>
                            </svg>

                            <div style={{width: 10}} />

                            {/* Facebook icon */}
                            <svg
                            style={{cursor:'pointer'}}
                            onClick={() => window.open('https://www.facebook.com/Fair-Art-Fair-114155854099956/', '_blank')}
                            width="32" height="32" viewBox="0 0 32 31.985"
                            >
                                <path d="M300.877,633.392a16,16,0,0,0-.6,31.985V653.556H296.3v-4.632h3.975v-3.41c0-3.954,2.414-6.106,5.941-6.106a32.419,32.419,0,0,1,3.564.183v4.132H307.35c-1.919,0-2.29.912-2.29,2.25v2.951h4.587l-.6,4.632h-3.99v11.28a16,16,0,0,0-4.183-31.444Z" transform="translate(-284.877 -633.392)"/>
                            </svg>

                            <div style={{width: 10}} />

                        </div>

                    </div>

                    <div style={styles.sectionRight}>

                        {/* <RegularText text={`Login ›`} /> */}
                        <RegularText text={`About ›`} link='/about' />
                        <RegularText text={`Manifesto ›`} link='/manifesto' />
                        <RegularText text={`Subscribe ›`} link='/subscribe' />
                        <RegularText text={`FAQs ›`} link='/faqs' />
                        <RegularText text={`Who Art you? ›`} link='/6' />
                        <div style={{height: 70}} />
                        <RegularText text={`contact@fairartfair.art`} type='email' />

                    </div>

                </div>

                {/* Bottom section */}
                <div style={Object.assign({}, styles.bottomSection, { width: width*0.75 })}>

                    <div style={styles.row}>

                        <SmallRegularText text={`Copyright © 2022 Fair Art Fair ®, operated by Fair Art Fair Limited, Company No. 12811420 `} />
                        <SmallRegularText text={`| Privacy Policy `} link='/privacypolicy' />
                        <SmallRegularText text={`| Terms & Conditions `} link='/termsandconditions' />
                        <button
                        style={{backgroundColor: 'transparent', padding: 0, border: 0, cursor: 'pointer'}}
                        onClick={() => setShowManageGdpr(true)}
                        >
                            <SmallRegularText text={`| Manage Cookies `} cursor={true} />
                        </button>

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <img
                        style={styles.image}
                        src={Unit1Logo}
                        onClick={() => window.open('https://unit1gallery-workshop.com/', '_blank')}
                        />

                        <div style={{height: 30}} />

                        <img
                        style={styles.image2}
                        src={ArtCouncilEnglandLogo}
                        />
                    </div>

                </div>
                
            </div>                
        </>
        
    )
}

export default Footer;