import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function TermsConditions() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>
                
                <BigBoldText text={`T&Cs`} />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                    <BoldText text={`\nTerms of Sale`} />

                    <RegularText text={`Fair Art Fair is an online community for artists, art lovers, curators, and art curious. Fair Art Fair facilitates connecting, provides app based tools, communication tools as well as support for all individuals integral to the art world. Fair Art Fair is a new world of art for discovery and to be discovered, a circular economy that returns profits from subscriptions back into the community.
                    \nThese Terms of Use (“Terms”) are an agreement between you (“you” or “your”) and Fair Art Fair, Unit 1 Gallery-Workshop Ltd, Unit 1, 1 Bard Road, London W10 6TP (UK) (“Fair Art Fair” or “we” or “our” or “us”) that governs your access to and use of our app & website app, products, and services (collectively, “Services”). By accessing or using our Services in any way, you agree to these Terms. If you do not agree to these Terms, you are not permitted to access or use our Services. If you have any questions please email us at hello@fairartfair.art`} />
                    
                    <BoldText text={`\n1. Basic Terms`} />
                    <RegularText text={`\n1.1 You may use our Services only if you can form a binding contract with us, and only as permitted by law. You are not allowed to use our Services if you are a person under the age of thirteen (13). Additional eligibility requirements may apply to some Services, and we will notify you of those requirements in these Terms or otherwise in connection with those Services.
                        \n1.2 If you are using our Services on behalf of a company or other entity, you represent and warrant that you have reached the age of majority in the jurisdiction you reside, and that you are authorised to bind that company or other entity to these Terms, in which case the terms “you” and “your” in these Terms will refer to that entity.
                        \n1.3 We offer a variety of Services, and additional guidelines, terms and conditions may apply to some Services (“Service Terms”). By using those Services, you agree to their Service Terms. If any of our Services have Service Terms that conflict with these Terms, those Service Terms will control to the extent of the conflict as relates to those Services.
                        \n1.4 For information about our privacy practices, please see our Privacy Notice, which can be viewed from our website.
                        \n1.5 You will need to subscribe to Fair Art Fair in order to use some of our Services. You agree to provide only true and accurate information in connection with your account, and to update all such information as necessary to keep it accurate and current. You agree not to create multiple or serial accounts for disruptive and abusive purposes.
                        \n1.6 You are responsible for maintaining the confidentiality of your account password, and for all activities that occur under or through your account or password. You agree to notify us immediately if you suspect or become aware of any unauthorised access to or use of your account or password or other breach of security on our Services. Fair Art Fair cannot and will not be liable for any loss or damage arising from your failure to comply with the above.1.7 The permission we give you with subscribing to a Fair Art Fair account and use our Services under these Terms is non-exclusive and non-transferable. You are not allowed to rent, sell, lease, sublicense or otherwise transfer your Fair Art Fair account, or any access to or use of our Services, to any third party.
                        \n1.8 We may reclaim or repurpose any username or URL on our Services, at any time at our sole discretion, for any reason, including if we believe it is necessary to comply with the rights of a third party.
                        \n1.9 You consent to receive communications from us electronically, and you agree that we may communicate with you by posting notices on our Services and/or by email. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing. You agree that all notices we provide to you by email will be considered received by you on the day that we send them. If you subscribe to a Fair Art Fair account or otherwise provide us with an email address, you agree that we are not responsible for any automatic filtering that you or your network provider may apply to any email that we send to the email address you provided.
                        \n1.10 You are responsible for providing any equipment (such as computers or mobile devices) and network access necessary for you to use our Services or communicate with us at your own cost.
                        \n1.11 You are responsible for your use of the Services, for any Content you post to the Services, and for any consequences thereof. The Content you submit, post or display may be able to be viewed by other users of the Services and through third party services and websites. You should only provide Content that you are comfortable sharing with others under these Term`} />
                
                    <BoldText text={`\n2. Definitions`} />
                    <RegularText text={`\nIn these Terms:
                        \n• “User” means any person or entity that accesses or uses our Services in any way, whether or not they subscribe to a Fair Art Fair account, including you.
                        • “Content” means any and all images, text, information, data, audio, video, graphics, computer code, software, and other material provided on or through our Services, including when we send you email. Content includes both Fair Art Fair Content and User Content.
                        • “Fair Art Fair Content” means any and all Content that we provide on or through our Services, including Content licensed from a third party, but excluding User Content. “User Content” means any and all Content that a User submits, posts, publishes or otherwise provides on or through our Services.
                        • “Your User Content” means any and all User Content that you submit, post, publish or otherwise provide on or through our Services.
                        • “on our Services” means on the Fair Art Fair app or website app
                        • “including” means “including but not limited to” unless we specifically indicate otherwise`} />

                    <BoldText text={`\n3. Ownership`} />
                    <RegularText text={`\n3.1 All Content is owned by Fair Art Fair by others who have licensed their Content to us, and is protected by national and international copyright laws, trademark laws, and/or other proprietary rights and laws as applicable.
                        \n3.2 The trademarks, service marks, logos, and product names displayed on or in connection with our Services are the registered and unregistered trademarks and service marks of Fair Art Fair or third parties the UK and/or other countries.
                        \n3.3 As between you and Fair Art Fair, Fair Art Fair owns and retains, solely and exclusively, all rights, title, and interest in and to our Services, the look and feel, design and organisation of our Services, all Fair Art Fair Content, and the compilation of all Content on our Services, including all copyrights, moral rights, trademark rights, trade secrets, patent rights, database rights, and other intellectual property and proprietary rights therein.
                        \n3.4 These Terms do not grant you any ownership over any Content, or any intellectual property rights in any Content, although you remain the owner of any intellectual property rights that you may have in Your User Content.
                        \n3.5 Neither these Terms nor your use of our Services grants you any license or permission under any copyright, trademark, or other intellectual property of Fair Art Fair or any third party, whether by implication or otherwise.
                        \n3.6 These Terms do not grant you the right to use any of our trademarks, service marks, logos, product names, domain names, or other distinctive brand features in any way.
                        \n3.7 We welcome feedback, comments and suggestions about our Services (“Feedback”). However, you acknowledge and agree that we will have the right to use any and all Feedback at our sole discretion, for any and all purposes, commercial or otherwise, without any obligation of any kind to you. In any case, we will have no obligation to act on, use or respond to any Feedback in any way.
                        \n3.8 We reserve all rights not expressly granted to you in these Terms.`} />

                    <BoldText text={`\n4. General Content Terms`} />
                    <RegularText text={`\n4.1 Please note that Content covers a wide range of art and subject matter, is generally uncensored, and may include nudity or other visual or written material that some people may consider offensive or inappropriate for children and minors. If you allow your child or anyone else to use your computer or other device, it is solely your responsibility to prevent them from accessing any Content that you think is or may be inappropriate for them.
                        \n4.2 Subject to these Terms, you may access and view Content within our Services for your own personal, non-commercial use, in the context of your Fair Art Fair User experience, in accordance with the normal functionality and restrictions of our Services.
                        \n4.3 We value open source software, and some software used in our Services may be offered under an open source license that we will make available to you. The open source license may contain provisions that override some of these Terms as relates to the use of that software.
                        \n4.4 Some Services may enable you to post, embed or transmit some Content in a way that will be accessible to others on our Services or in other locations online (such as other websites and social media services). You may use such Services in accordance with their normal functionality and restrictions, as permitted by any applicable Service Terms. However, the availability of such Services does not imply or give you permission to reproduce, distribute or otherwise use such Content in any other way, whether on our Services or in any other location.
                        \n4.5 Except as expressly permitted by these Terms, you must not reproduce, distribute, adapt, modify, translate, create derivative works from, publish or otherwise use any Content in any way without express prior written permission from us or (as applicable) the appropriate third-party rights holder.
                        \n4.6 Any commercial exploitation of any image or other Content without express prior written permission from us or (as applicable) the appropriate third-party rights holder, including any commercialised reproduction, distribution, publishing, or creation of derivative works, is strictly prohibited`} />

                    <BoldText text={`\n5. User Content and Communications`} />
                    <RegularText text={`\n5.1 Some Services may enable Users to submit, post, publish or otherwise provide User Content. Each User is solely responsible for all User Content that they provide on or through our Services.
                        \n5.2 We do not endorse or make any representations or warranties of any kind with respect to any User Content and/or any statements, ideas, advice or opinions communicated on, through, or in connection with our Services (whether online, offline, orally, in writing or otherwise) by any User and/or third party, whether with respect to accuracy, completeness, truthfulness, reliability or otherwise. You acknowledge that any use of or reliance on any User Content and/or any such statements, ideas, advice or opinions is solely at your own risk.
                        \n5.3 You acknowledge that we have no obligation to review or screen any User Content, and that by using our Services, you may be exposed to User Content that is inaccurate, misleading, offensive or otherwise objectionable.
                        \n5.4 Some User Content may be marked as “featured” (or marked with other similar language) when it is provided on or through our Services. Such markings are for general informational use only and are not an endorsement, representation or warranty of any kind by us.
                        \n5.5 Some User Content may be available for sale against payment by another community member. The transaction is not conducted by Fair Art Fair and therefore relies upon the two interested parties to conclude sales independently.
                        \n5.6 We reserve the right, but have no obligation, to monitor and/or review any and all User Content and/or communications transmitted on or through our Services, to enforce or investigate potential violations of these Terms or our other policies or agreements with Users, to detect, prevent or otherwise address fraud, security or technical issues, or to otherwise administer, improve or operate our Services and/or customer support, without notice or liability, at any time at our sole discretion. We also reserve the right to access, preserve and disclose any information if we believe it is reasonably necessary to: (i) respond to claims against us or comply with any law, regulation, legal process or governmental request; (ii) enforce or administer these Terms or our other policies or agreements with Users; (iii) conduct customer support, or detect, prevent or otherwise address fraud, security or technical issues; or (iv) protect or enforce the rights, property or safety of Fair Art Fair, you, or others.
                        \n5.7 We reserve the right to remove or refuse to display any User Content on our Services, in whole or part, if we believe that such User Content may violate these Terms, the law or any third-party rights, or for any other reason, without notice or liability, at any time at our sole discretion.
                        \n5.8 You acknowledge that you are in the best position to know if Your User Content may be used in connection with our Services. You are solely responsible for ensuring that Your User Content does not violate any law or regulation, or any right or intellectual property of any third party, including any copyright, moral right, trademark right, trade secret, patent right, privacy right, publicity right, or contractual right.
                        \n5.9 By submitting, posting, publishing or otherwise providing any User Content on or through our Services, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub licensable (through multiple tiers) right and license to use, host, store, reproduce, distribute, publish, adapt, modify, translate, create derivative works from, publicly perform, and publicly display Your User Content, in any and all media now known or later developed, for the purposes of operating, distributing, promoting, and improving our Services. Developing new Services, syndication, broadcast, distribution or publication of such Content on other media and services, subject to our terms and conditions for such Content use. You represent and warrant that: (i) you own or otherwise control all of the rights to Your User Content; and (ii) the use of Your User Content does not violate these Terms and will not violate any right of, or cause injury to, any person or entity. We may modify or adapt Your User Content in order to transmit, display or distributed it over computer networks, and in various media and/or make changes to your Content as are necessary to conform and adapt that Content to any requirements in limitation of any networks, devices, services or media.
                        \n5.10 Nothing in these Terms will restrict any other rights that we may have or later obtain with respect to Your User Content, such as rights under applicable laws or other licenses.
                        \n5.11 All Content, whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such Content. We may not monitor or control the Content posted via the Services and we cannot take any responsibility for such Content. Any use or reliance on any Content or materials posted via the Services or obtained by you through the Services is at your own risk.`} />

                    <BoldText text={`\n6. Open Access Images`} />
                    <RegularText text={`\n6.1 Certain images of artwork or other objects displayed on our Services may be available to download and use independently outside our Services (“Open Access Images”). Open Access Images are sourced from third parties (such as certain museums or other organisations) that have made the images available for unrestricted use, or for use under certain conditions (such as attribution requirements or additional licenses).
                        \n6.2 In order to notify you which images are Open Access Images, we will provide a button marked “Download Image” (or an equivalently marked image downloading tool) with each Open Access Image on our Services (“Image Downloading Tool”). Where available, the Image Downloading Tool will be provided on the listing page for the artwork or other object depicted in an Open Access Image. Please note that if we do not provide an Image Downloading Tool with an image on our Services, that image is not considered an Open Access Image under these Terms.
                        \n6.3 You may download Open Access Images from our Services using the Image Downloading Tool that we provide, and you may use Open Access Images without restriction, subject to any applicable laws, and any applicable third-party rights and conditions. However, please note that this permission applies only to any rights that Fair Art Fair Fair itself may have with respect to the use of Open Access Images.
                        \n6.4 We are not granting any permission or otherwise acting on behalf of any third party in connection with any Open Access Images. We do not represent or warrant that the use of any Open Access Images in any way will not violate the copyrights or other rights of third parties (such as trademark rights, privacy rights or publicity rights).
                        \n6.5 When you download or use Open Access Images in any way, you are solely responsible for determining and complying with any applicable third-party rights and conditions.
                        \n6.6 To help you determine what conditions may apply to the use of Open Access Images, certain rights information, source-identifying information, and conditions may be posted on our Services (for example, as part of the listing for the artwork or other object depicted in an Open Access Image). However, you agree that we have no obligation to post any such information or conditions on our Services, and that whether or not any such information or conditions are posted on our Services, additional conditions not posted on our Services may still apply, and you are responsible for determining and complying with them.
                        \n6.7 You acknowledge and agree that: (i) your use of Open Access Images is at your own risk; and (ii) we will not be liable to you or any third party for any claims, demands, damages or losses of any kind resulting directly or indirectly from your use of or inability to use any Open Access Images in any way.`} />

                    <BoldText text={`\n7. Behaviour tracking and Surveys`} />
                    <RegularText text={`\n7.1 All the visits are recorded with Google Analytics. This is done with the purpose of tracking the company performance.`} />

                    <BoldText text={`\n8. Remuneration and Payment`} />
                    <RegularText text={`\n8.1. Payment can be made through various payment methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards or online payment methods (PayPal, for example). All utilising Stripe on-line payment services.
                        \n8.2 Subscriptions Payment cards (credit cards or debit cards) are subject to validation checks and authorisation by Your card issuer. If we do not receive the required authorisation, We will not be liable for any delay or nondelivery of Your Order.
                        \n8.3 Subscription periodThe Service or some parts of the Service are available only with a paid Subscription. You will be billed in advance on a recurring and periodic basis annually.At the end of each period, Your Subscription will automatically renew under the exact same conditions unless You cancel it or the Company cancels it.Subscription cancellationsYou may cancel Your Subscription renewal either through Your Account settings page or by contacting the Company.You will not receive a refund for the fees You already paid for Your current Subscription period and You will be able to access the Service until the end of Your current Subscription period.
                        \n8.4 BillingYou shall provide the Company with accurate and complete billing information including full name, address, telephone number, and a valid payment method information.Should automatic billing fail to occur for any reason, the Company will issue send notification by email 
                        \n8.5 Fee ChangesThe Company, in its sole discretion and at any time, may modify the Subscription fees. Any Subscription fee change will become effective at the end of the then-current Subscription period.The Company will provide You with reasonable prior notice of any change in Subscription fees to give You an opportunity to terminate Your Subscription before such change becomes effective.Your continued use of the Service after the Subscription fee change comes into effect constitutes Your agreement to pay the modified Subscription fee amount.
                        \n8.6 RefundsExcept when required by law, paid Subscription fees are non-refundable.Certain refund requests for Subscriptions may be considered by the Company on a case-by-case basis and granted at the sole discretion of the Company.
                        \n8.7 Free TrialThe Company may, at its sole discretion, offer a Subscription with a Free trial for a limited period of time.You may be required to enter Your billing information in order to sign up for the Free trial.If You do enter Your billing information when signing up for a Free Trial, You will not be charged by the Company until the Free trial has expired. On the last day of the Free Trial period, unless You cancelled Your Subscription, You will be automatically charged the applicable Subscription fees for the type of Subscription You have selected.At any time and without notice, the Company reserves the right to (i) modify the terms and conditions of the Free Trial offer, or (ii) cancel such Free trial offer.`} />

                    <BoldText text={`\n9. General Restrictions`} />
                    <RegularText text={`\n9.1 You agree that you will not (and will not attempt to, or assist or encourage any other person or entity to):
                        • submit, post, publish or otherwise provide any User Content on or through our Services, or take any other action in connection with our Services (whether online or offline), that: (i) infringes, misappropriates or violates the rights or intellectual property of any third party, including any copyright, moral right, trademark right, trade secret, patent right, privacy right, publicity right, or contractual right: (ii) violates (or encourages any conduct that would violate) any applicable local, state, national, or international law or regulation, including any tax law, consumer protection law, or law governing the export or import of data, goods, services or software; (iii) is deceptive, misleading, fraudulent, defamatory, libellous, abusive, harassing, discriminatory, hateful, malicious, inciting of violence, threatening, sexually explicit, or obscene; or (iv) impersonates or misrepresents your relationship with any person or entity;
                        • create a false or misleading Fair Art Fair account or User profile with inaccurate or untrue information;
                        • use our Services to list, market, offer for sale, or sell goods or services without our express prior written permission;
                        • use our Services or any Content in connection with posting or distributing spam or other unauthorised or unsolicited advertising, promotional messages, or bulk electronic communications;
                        • collect information about Users (including email addresses) or send marketing email or other promotional communications to Users without their consent;
                        • access our Services by any means other than the interface and instructions that we provide;• access, tamper with, or use non-public areas of our Services, our computer systems, or the technical delivery systems of our service providers;
                        • breach, disable or circumvent any security or authentication measures on or in connection with our Services;
                        • interfere with the normal operation of our Services or the access of any User, including transmitting any viruses or harmful code, flooding our Services with excessive requests or traffic, or taking any other action that creates (at our sole discretion) an unreasonable or disproportionately large load on our servers or systems;
                        • decipher, decompile, disassemble, reverse engineer, or otherwise derive or extract any source code or underlying ideas or algorithms of, any of our Services;
                        • adapt, modify, create derivative works from, or redistribute any of our Services without our express prior written permission;
                        • use any robot, scraper, spider, or other automatic or manual process to monitor or extract data from our Services without our express prior written permission (we may also use robot exclusion headers within our Services and you agree to comply with all such headers);
                        • forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use our Services or any Content to send altered, deceptive, or false source-identifying information;
                        • mirror, frame or display any part of our Services on any other website or elsewhere without our express prior written permission;
                        • use any meta-tags or other hidden text or metadata containing any Fair Art Fair trademark, service mark, product name, or URL without our express prior written permission;
                        • use any Fair Art Fair trademark, service mark, product name, logo or URL in any way that incorrectly suggests our affiliation with or endorsement of any person, entity, event, product or service;
                        • use any trademark, service mark, product name, logo, trade dress, or design that is in any way confusingly similar to any Fair Art Fair trademark, service mark, product name, or logo, or to the look and feel of any of our Services; or
                        • remove, conceal, modify or tamper with any copyright, trademark, or other proprietary marking or notice, or any digital watermark or other technical measure used to indicate the source or ownership of any image or other Content.
                        \n9.2 Without limiting other rights or remedies, we reserve the right to limit, terminate or suspend any User’s account and/or access to or use of any or all of our Services, at any time at our sole discretion, if we believe that such User is violating the rights of any third party, that such User’s conduct may be exposing us or others to legal or financial liability, or that such User is acting inconsistently with the letter or spirit of these Terms, and we will have no liability to such User for doing so. In any case, we reserve the right to investigate and prosecute violations of these Terms to the fullest extent permitted by applicable law.`} />

                    <BoldText text={`\n10. Right of Revocation for Consumers`} />
                    <RegularText text={`\nCancellation Policy / Right of Revocation
                        \nYou have the right to revoke this contract within ten days without giving reasons. The revocation period is 10 days from the day of conclusion of contract. In order to exercise your right of revocation, you must inform us by email cancel@fairartfair.art
                        \nAfter 10 days grace period, subscribers to Fair Art Fair have the right to cancellation at any time and can do so within the app and web app. Subscriptions are sold on a yearly basis and subscribing cancellations will result in the auto subscription renewal being removed, Fair Art Fair will notify subscribers of cancelation. No refunds will be applicable, regardless of the term/time left within a years subscription. End of the Cancellation Policy`} />

                    <BoldText text={`\n11. Other Websites and Services`} />
                    <RegularText text={`\n11.1 The Services may contain links to third-party websites or resources. You acknowledge and agree that Fair Art Fair is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement by Fair Art Fair of such websites or resources or the content, products or services available from such websites or resources.10.2 You are solely responsible for, and assume sole risk arising out of, any use of or reliance on any such websites or services, or any information, materials, goods or services on or available from any such websites or services.
                        \n11.2 You acknowledge and agree that Fair Art Fair are not responsible and will not be liable for: (i) the accuracy or availability of any such websites or services; or (ii) any information, materials, goods or services on or available from any such websites or services.`} />

                    <BoldText text={`\n12. Disclaimer of Warranties`} />
                    <RegularText text={`\n12.1 You acknowledge and agree that your access to and use of our services is at your own risk, and that our services are provided to you on an “as is” and “as available” basis. We make no representations or warranties of any kind, express or implied, with respect to our services or any content, including any warranties of merchantability, fitness for a particular purpose, title, non-infringement, and quiet enjoyment, and any warranties arising out of course of dealing or usage of trade, all of which Fair Art Fair and our officers, owners, directors, consultants, agents, and employees expressly disclaim to the fullest extent permitted by applicable law. Fair Art Fair makes no representations or warranties of any kind, express or implied, that our services or any content will meet your requirements or be available, accessible, uninterrupted, timely, accurate, reliable, complete, free of errors, safe, secure, or free of viruses or other harmful components, or that the use of our services in any way will give rise to any specific results. Applicable law may not permit the exclusion of implied warranties, so some of the exclusions in this section may not apply to you.
                        \n12.2 If the use of our Services results in the need for servicing or replacing any equipment or data, Fair Art Fair will not be responsible for those costs or losses, and we urge you to backup your data at all times.
                        \n12.3 No advice or information (whether oral or written) obtained from Fair Art Fair or through our Services or any Content, will create any warranty by us not expressly stated in these Terms or in a separate written agreement between you and us.`} />
                    
                    <BoldText text={`\n13. General Release`} />
                    <RegularText text={`\n13.1 We may provide rules for using our Services, but Fair Art Fair does not endorse or control and are not responsible for the conduct (whether online or offline) of any User and/or third party on or in connection with our Services.13.2 To the fullest extent permitted by applicable law, you releases Fair Art Fair from all claims, demands, suits, damages (actual and consequential), losses, liabilities, and expenses (including attorneys’ fees), of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in connection with: (i) any disputes between you and one (1) or more other users or other third parties resulting directly or indirectly from your use of our services; (ii) any user content or conduct, performance or nonperformance (whether online or offline) of any other user or other third party in any way connected with our services, including any defamatory, misleading, offensive or unlawful conduct or user content, or any unauthorised access to or use of our services, your account or your user content and/or information; (iii) any artwork or other property exhibited, listed, marketed, offered for sale, or sold (whether online or offline) by any other user or other third party on, through, or in any way connected with our services; or (iv) any communications, interactions or meetings (whether in person, online or otherwise) between you and one (1) or more other users or other third parties resulting directly or indirectly from your use of our services. You expressly waive any benefits or protections, whether statutory or otherwise, that would otherwise limit the coverage of this release to include only those claims which you may know or suspect to exist in your favour at the time of entering into this release.`} />
                    
                    <BoldText text={`\n14. Limitation of Liability`} />
                    <RegularText text={`\n14.1 Under no circumstances will Fair Art Fair be liable to you or any third party for any indirect, incidental, exemplary, punitive, special, or consequential damages, any personal or bodily injury or emotional distress, or any loss of profits, revenue, business, data, use, goodwill or other intangible losses, arising out of or in connection with: (i) these terms; (ii) our services; (iii) the use of or inability to use our services; (iv) any conduct, performance or non-performance (whether online or offline) of any other user or other third party on or in connection with our services; or (v) any content, goods or services made available on, through, or in any way connected with our services (whether online or offline) by us or any other user or other third party. Under no circumstances will our total liability to you or any third party arising out of or in connection with these terms or our services exceed the greater of one hundred euros (€100,00) or the total amount you paid us (if any) to use our services in the six (6) months before the event giving rise to such liability.
                        \n14.2 The exclusions and limitations of liability provided above in subsection 13.1 apply to all claims, whether based on warranty, contract, statute, tort (including negligence), strict liability, or any other legal theory, whether or not any of the Fair Art Fair parties have been advised of or should have known of the possibility of such damage or loss, and even if a remedy set forth in these terms is found to have failed of its essential purpose. Applicable law may not permit the exclusion or limitation of liability for consequential or incidental damages, so some of the exclusions and limitations provided above in subsection 13.1 may not apply to you, in which case the liability of the Fair Art Fair parties will be limited to the fullest extent permitted by applicable law.`} />
                    
                    <BoldText text={`\n15. Indemnification`} />
                    <RegularText text={`\n15.1 You agree to indemnify, defend, and hold harmless Fair Art Fair and our officers, directors, agents, and employees from and against any and all third-party claims, demands, suits, and proceedings, and all related damages, losses, judgments, liabilities, and expenses (including reasonable attorneys’ fees) arising out of or related to: (i) your breach of any of these Terms (including any term or condition incorporated into these Terms by reference); (ii) Your User Content; (iii) any misrepresentation made by you; or (iv) your violation of any law or the rights of any third party, including any intellectual property right, privacy right, or publicity right.
                        \n15.2 We reserve the right to assume the exclusive defence and control of any matter subject to indemnification by you, in which case you agree to cooperate as fully as reasonably required by us in conducting such defence. In any case, you agree not to settle any such matter without our prior written consent. Your indemnification and defence obligations provided in these Terms will survive these Terms and your use of our Services.`} />
                    
                    <BoldText text={`\n16. Choice of Law, Place of Jurisdiction`} />
                    <RegularText text={`\n16.1 "This agreement, and any dispute, controversy, proceedings or claim of whatever nature arising out of or in any way relating to this agreement or its formation (including any noncontractual disputes or claims), shall be governed by and construed in accordance with English law."`} />
                    
                    <BoldText text={`\n17. International Use`} />
                    <RegularText text={`\n17.1 If you are located outside of the UK, you agree to ensure that your access to and use of our Services complies with all applicable local, state, national, and international laws and regulations, including any laws and regulations governing the import or export of data, goods, services or software.
                        \n17.2 You must not access or use our Services if any applicable local, state, national, or international law or regulation prohibits you from receiving products or services originating from the UK.`} />
                    
                    <BoldText text={`\n18. Changes To Our Services`} />
                    <RegularText text={`\n18.1 Our Services are updated frequently, and their form and functionality may change without notice. We reserve the right to change, modify, add, remove or discontinue any and all of our Services (and/or the appearance, design, functionality, and all other aspects of any and all of our Services), in whole or part, temporarily or permanently, with or without notice, for any reason or no reason, at any time at our sole discretion.
                        \n18.2 We will not be liable to you or any third party for any damages or losses of any kind resulting directly or indirectly from any changes made by us to our Services, including any loss of business or the ability to use any product, service or Content.`} />
                    
                    <BoldText text={`\n19. Changes to These Terms`} />
                    <RegularText text={`\n19.1 Our business changes with time, and these Terms will change also. We reserve the right to change these Terms, including the right to change, modify, add or remove any part of these Terms, without prior notice, at any time at our sole discretion.
                        \n19.2 All changes to these Terms will be effective when posted on our Services, or at such later date as may be specified in the updated Terms.
                        \n19.3 By continuing to use our Services after any changes to these Terms become effective, you agree to such changes and the updated Terms.\n19.4 You agree that we may notify you of any changes to these Terms by posting the updated Terms on our Services, and you agree to review these Terms regularly and inform yourself of all applicable changes.
                        \n19.5 If you do not agree to any changes made by us to these Terms, you will no longer be permitted to use our Services.
                        \n19.6 Changes to these Terms will not apply to any claim or dispute that arose before such changes became effective, all of which will remain subject to the version of these Terms in effect at the time that such claim or dispute arose.
                        \n19.7 We will not be liable to you or any third party for any damages or losses of any kind resulting directly or indirectly from any changes made by us to these Terms, including any loss of business or the ability to use any product, service or Content.`} />
                    
                    <BoldText text={`\n20. Termination`} />
                    <RegularText text={`\n20.1 You may stop using our Services at any time, subject to any other agreements between you and us.
                        \n20.2 Regardless of any other statement in these Terms, we reserve the right to terminate these Terms, to terminate your account (if you have an account), and/or to limit, terminate or suspend your access to or use of any or all of our Services, at any time at our sole discretion, without notice or liability, for any reason or no reason, including if you violate the letter or spirit of these Terms.
                        \n20.3 After any termination of these Terms and/or any termination of your access to or use of our Services, the following will survive and remain in full force and effect: (i) all outstanding obligations you may have to us under these Terms or otherwise; (ii) all remedies for breach of these Terms; and (iii) the following sections of these Terms: 3 (Ownership), 5 (User Content and Communications), 10 (Other Websites and Services), 11 (Disclaimer of Warranties), 12 (General Release), 13 (Limitation of Liability), 14 (Indemnification), 15 (Choice of Law, Place of Jurisdiction), 17 (Changes to our Services), 18 (Changes to these Terms), 19 (Termination), 21 (Miscellaneous).`} />
                    
                    <BoldText text={`\n21. Promotions`} />
                    <RegularText text={`\n21.1 We may offer or conduct promotions, sweepstakes or contests (“Promotions”) on or in connection with our Services from time to time, by ourselves or in conjunction with third parties.
                        \n21.2 Your participation in Promotions is subject to these Terms and any official rules that we may post on our Services or otherwise make available to you in connection with Promotions (“Promotion Rules”). If any Promotions have Promotion Rules that conflict with these Terms, those Promotion Rules will control to the extent of the conflict as relates to those Promotions.`} />
                    
                    <BoldText text={`\n22. Miscellaneous`} />
                    <RegularText text={`\n22.1 These Terms, including our Privacy Notice, and any other terms and conditions incorporated into these Terms by reference, together with any other written agreements that you may enter into with us regarding our Services, constitutes the entire agreement and understanding between you and us regarding our Services, and supersedes any other communications, representations or understandings.
                        \n22.2 If any provision of these Terms is found by a court of competent jurisdiction to be invalid or unenforceable for any reason, that provision will be enforced to the maximum extent permitted, and these Terms will otherwise remain in full force and effect.
                        \n22.3 No delay or failure by us to exercise or enforce any right or provision of these Terms will be considered a waiver. No right or remedy under these Terms will be considered waived by us unless the waiver is in writing and signed by an Fair Art Fair representative who intends and is duly authorised to agree to the waiver on our behalf. No single or partial exercise by us of any right or remedy under these Terms will prevent us from exercising any other right or remedy.
                        \n22.4 These Terms will be binding on your successors and assigns, but you are not allowed to assign, transfer or sublicense these Terms or any right or obligation under these Terms without our prior written consent. These Terms, and all rights granted to or reserved by us under these Terms, will benefit and be enforceable by our successors and assigns.
                        \n22.5 There are no third-party beneficiaries to these Terms except as expressly provided in these Terms.
                        \n22.6 No agency, partnership, joint venture, employment, sales representative, or franchise relationship is created or implied by these Terms or your use of our Services.
                        \n22.7 Nothing in these Terms will prevent us from complying with any applicable law or regulation.
                        \n22.8 These Terms were written in English. If any translation conflicts with the English version, the English version controls.
                        \n22.9 The section titles in these Terms are for convenience only and have no legal or contractual effect.`} />
                    
                    <BoldText text={`\n23. How to Contact Us`} />
                    <RegularText text={`\n23.1 If you have questions about these Terms or our Services, please email us 
                        at: hello@fairartfair.art
                        \nFair Art Fair a part of Unit 1 Gallery-Workshop Ltd, 1 Bard Road, London W10 6TP
                        \nRegister in the UK at Companies House Number 128111420`} />
                
                </div>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default TermsConditions;