
export default {

    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundColor: '#fff',
        overflow: 'hidden',
        zIndex: 1,
        backgroundColor: 'transparent',
    },

    containerMobile: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflow: 'hidden',
        zIndex: 1,
        backgroundColor: 'transparent'
    },

    image: {
        display: 'flex',
        flex: 1,
        position: 'absolute',
        height: '100%',
        width: '100%',
        marginLeft: '-10vw',
        objectFit: 'cover',
        zIndex: 0
    },

    imageMobile: {
        display: 'flex',
        flex: 1,
        position: 'absolute',
        height: '100%',
        width: '100%',
        marginLeft: '-5vw',
        objectFit: 'cover',
        zIndex: 0
    }

}