
export default {

    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        margin: 'auto',
        marginLeft: '-10vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff'
    },

    innerContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-start'
    },

    section: {
        display: 'flex',
        flexDirection:' column',
        flex: 1,
        alignItems: 'flex-end',
        padding: 0,
        marginRight: '2.5vw'
    },

    row: {
        display: 'flex',
        flexDirection: 'row'
    },

    section2: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        marginLeft: '2.5vw',
        marginRight: 0,
        marginBottom: 0,
        marginTop: 0,
    },

    sectionMobile: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: 0,
        marginLeft: '10vw',
        marginRight: 0,
        marginBottom: 0,
        marginTop: 0
    },

    section2Mobile: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '10vw',
        marginRight: 0,
        marginBottom: 0,
        marginTop: 30,
    }

}