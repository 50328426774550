import React from 'react'
// Components.
import BigBoldText from '../../../Components/Text/BigBoldText'
import RegularText from '../../../Components/Text/RegularText'
import BoldText from '../../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'

function AboutNine() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
                
            <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                <div style={styles.innerContainer}>

                    <div style={styles.section}>
                        <RegularText text={`We are a UK-based social enterprise,\nentirely conceived, designed, and led\nby Artists. The brainchild of Artist\nStacie McCormick, Fair Art Fair was\nborn from McCormick’s eleven years’\nexperience, including running\nLondon’s Unit 1 Gallery | Workshop.\nDedicated to strengthening Artists\nand enriching the lives of all Art world\nplayers, McCormick has supported `} />
                    </div>

                    <div style={styles.section2}>
                        <RegularText text={`Artists through many exhibitions, four\nannual residency programmes and\ndevelopmental projects both in the\nUK and US. In the past six years\nalone, she has worked closely with\nover 300 international emerging and\nmid-career contemporary Artists,\nmany of whom form the initial core\ncommunity of Fair Art Fair’s validated\nArtists.`} />
                    </div>

                </div>

            </div>
            
        )
  
    // Mobile version
    return (
            
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={Object.assign({}, styles.innerContainer, { flexDirection: 'column' })}>

                <div style={styles.sectionMobile}>
                    <RegularText text={`We are a UK-based social enterprise,\nentirely conceived, designed, and led\nby Artists. The brainchild of Artist\nStacie McCormick, Fair Art Fair was\nborn from McCormick’s eleven years’\nexperience, including running\nLondon’s Unit 1 Gallery | Workshop.\nDedicated to strengthening Artists\nand enriching the lives of all Art world\nplayers, McCormick has supported\nArtists through many exhibitions, four\nannual residency programmes and\ndevelopmental projects both in the\nUK and US. In the past six years\nalone, she has worked closely with\nover 300 international emerging and\nmid-career contemporary Artists,\nmany of whom form the initial core\ncommunity of Fair Art Fair’s validated\nArtists.`} />
                </div>

            </div>

        </div>
        
    )
}

export default AboutNine;