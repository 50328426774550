import React from 'react'
import { useSpring, animated, config } from '@react-spring/web'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function CuratorThree() {
    const [ width, height ] = WindowSize()

    const movement = useSpring({
        from: {opacity: 0, transform: 'translateY(100px)'},
        to: {opacity: 1, transform: 'translateY(0)'},
        config: config.molasses,
        delay: 800
    })

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/boModkT.jpg'}
                />

                <animated.div style={Object.assign({}, styles.container, movement, { width: width, height: height })}>

                    <BoldText text={`See`} />

                    <RegularText text={`With a global collection of\nvalidated Artists at your\nfingertips, it’s easy to browse,\nconnect and exhibit.`} />

                </animated.div>
            </>
        )
  
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/7HFqFn0.jpg'}
            />

            <animated.div style={Object.assign({}, styles.containerMobile, movement, { width: '90vw' })}>

                <BoldText text={`See`} />

                <RegularText text={`With a global collection of validated Artists at your fingertips, it’s easy to browse, connect and exhibit.`} />

            </animated.div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default CuratorThree;