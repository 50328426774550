import React from 'react'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function ArtLoverThree() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/dpI4Mlg.jpg'}
                />

                {/* <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                    <BoldText text={`Discover amazing art`} />

                    <RegularText text={`We make it easy to find great\nArt from across the globe –\nand it’s all peer-validated.`} />

                </div> */}
            </>
        )
  
    // mobile version
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/yeUtrvZ.jpg'}
            />

            {/* <div style={Object.assign({}, styles.containerMobile, { width: '90vw' })}>

                <BoldText text={`Discover amazing art`} />

                <RegularText text={`We make it easy to find great Art from across the globe – and it’s all peer-validated.`} />

            </div> */}

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default ArtLoverThree;