import React from 'react'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function ArtLoverEight() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/HM3XWhC.jpg'}
                />

                {/* <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                    <BoldText text={`Get organised`} />

                    <RegularText text={`Everything’s hosted in a\nsecure online environment\nwhere you can catalogue\nand organise your\ncollections, build wish lists\nand strike up\nconversations.`} />

                </div> */}
            </>
        )
  
    // mobile version
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/IbV8geg.jpg'}
            />

            {/* <div style={Object.assign({}, styles.containerMobile, { width: '90vw' })}>

                <BoldText text={`Get organised`} />

                <RegularText text={`Everything’s hosted in a secure online environment where you can catalogue and organise your collections, build wish lists and strike up conversations.`} />

            </div> */}

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default ArtLoverEight;