import React from 'react'
// Components.
import RegularText from '../../../Components/Text/RegularText'
import BoldText from '../../../Components/Text/BoldText'
// Styling.
import WindowSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'

function ContentItem({ title, text }) {
    const [ width, height ] = WindowSize()

    return (
        <div style={{paddingTop: width > Breakpoints.mobile ? 40 : 20}}>

            <BoldText text={title} />
            <RegularText text={text} />

        </div>
    )
}

export default ContentItem;