import { useState } from 'react'
// AWS.
import { API, graphqlOperation } from 'aws-amplify'

export default () => {
    const [ loadingStats, setLoading ] = useState( 'static' )
    const [ resultStats, setResult ] = useState()

    const listWebStatsHistorys = /* GraphQL */ `
        query ListWebStatsHistorys(
            $filter: ModelWebStatsHistoryFilterInput
            $limit: Int
            $nextToken: String
        ) {
            listWebStatsHistorys(
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            ) {
            items {
                id
                ios
                android
                art_lovers
                artists
                curators
                total
                not_subscribed
                date_int
            }
            nextToken
            }
        }
    `

    async function SubscriberStats() {
        try {
            setLoading('loading')

            const response = await API.graphql(graphqlOperation(listWebStatsHistorys))
            
            setResult(response.data.listWebStatsHistorys)
            setLoading('loaded')

        } catch (err) {
            console.log('error getting subscriber stats', err)
            setLoading('static')
        }
    }

    return [ SubscriberStats, loadingStats, resultStats ]
}