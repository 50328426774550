import React from 'react'
import { useSpring, animated, config } from '@react-spring/web'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function ArtLoverFour() {
    const [ width, height ] = WindowSize()

    const movement = useSpring({
        from: {opacity: 0, transform: 'translateY(100px)'},
        to: {opacity: 1, transform: 'translateY(0)'},
        config: config.molasses,
        delay: 800
    })

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/q3OMa1s.jpg'}
                />

                <animated.div style={Object.assign({}, styles.containerRight, movement, { width: width, height: height })}>

                    <BoldText text={`Art & more`} />

                    <RegularText text={`Find shipper, framers\ninstallations teams… We’ve\ngot your back, just ask. `} />

                </animated.div>
            </>
        )
  
    // mobile version
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/mJuVVZ0.jpg'}
            />

            <animated.div style={Object.assign({}, styles.containerMobile, movement, { width: '90vw' })}>

                <BoldText text={`Art & more`} />

                <RegularText text={`Find shipper, framers installations teams… We’ve got your back, just ask. `} />

            </animated.div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default ArtLoverFour;