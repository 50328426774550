import React from 'react'
// Components.
import BigRegularText from '../../Components/Text/BigRegularText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'

function NoMatch() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>
            
            <BigRegularText text="It seems you are lost" />

        </div>
    )
}

export default NoMatch;