import React, { useState, createContext } from 'react'

const GeneralContext = createContext()

export function GeneralProvider ({ children }) {
    const [ showManageGdpr, setShowManageGdpr ] = useState(false)

    return (
        <GeneralContext.Provider value={{
            showManageGdpr,
            setShowManageGdpr
        }}>
            {children}
        </GeneralContext.Provider>
    )
}

export default GeneralContext;