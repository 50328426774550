import React from 'react'
// Components.
import RegularText from '../../Components/Text/RegularText'
import BoldText from '../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function CuratorSix() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            <>
                <img
                style={styles.image}
                src={'https://i.imgur.com/1fhdq24.jpg'}
                />

                <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                    <BoldText text={`Be seen`} />

                    <RegularText text={`Submit your exhibition ideas\nwhere they can be\nconsidered for an exhibitions\nIRL or on the platform.`} />

                </div>
            </>
        )
  
    return (
        <>
            <img
            style={styles.imageMobile}
            src={'https://i.imgur.com/NwWQD0n.jpg'}
            />

            <div style={Object.assign({}, styles.containerMobile, { width: '90vw' })}>

                <BoldText text={`Be seen`} />

                <RegularText text={`Submit your exhibition ideas  where they can be considered for an exhibitions IRL or on the platform.`} />

            </div>

            <div style={{display: 'flex', flex: 1}} />
        </>
    )
}

export default CuratorSix;