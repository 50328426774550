import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// Styling.
import styles from './styles.js'
import WindowSize from '../General/windowsSize'
import Breakpoints from '../General/breakpoints'

function SelectRightSide() {
    let location = useLocation()
    let history = useHistory()
    const [ width, height ] = WindowSize()

    return (
        // Manage what right icon to display depending on whether the menu is showing or not
        // If going to menu page directly, on close menu go to homepage, otherwise action like you would expect it to.
        <button
        style={Object.assign({}, styles.menu, { marginRight: width > Breakpoints.mobile ? 30 : 20})}
        onClick={() => history.length <= 2 ?
            history.push('/')
        :
            location.pathname === '/faqs' || location.pathname === '/termsandconditions' || location.pathname === '/menu' ?
                history.goBack()
            :
                history.push('/menu')
        }>
            {
                location.pathname === '/menu' || location.pathname.charAt(1) === 'f' || location.pathname.charAt(1) === 't' ?
                    <svg
                    viewBox="0 0 15.359 15.359"
                    fill={'#000'}
                    height={'1em'}
                    width={'2em'}
                    >
                        <g transform="translate(-1331.813 -19.857)">
                            <g transform="translate(412.455 -939.753) rotate(45)">
                                <path d="M1349.452,27.563v1.8h-19.921v-1.8Z"/>
                            </g>
                            <g transform="translate(372.202 954.574) rotate(-45)">
                                <path d="M1349.452,27.563v1.8h-19.921v-1.8Z"/>
                            </g>
                        </g>
                    </svg>
                :
                    <svg
                    viewBox="0 0 19.921 14.374"
                    fill={'#000'}
                    height={'1em'}
                    width={'2em'}
                    >
                        <g transform="translate(-1329.531 -21.276)">
                            <path d="M1349.452,21.014v1.8h-19.921v-1.8Z"/>
                            <path d="M1349.452,27.563v1.8h-19.921v-1.8Z"/>
                            <path d="M1349.452,34.226v1.8h-19.921v-1.8Z"/>
                        </g>
                    </svg>
            }
        </button>
    )
}

export default SelectRightSide;