import React from 'react'
import { Link } from 'react-router-dom'
// Styling.
import WindowSize from '../General/windowsSize'

function SmallRegularText({ text, color, link, marginTop, marginBottom, marginLeft, cursor }) {
    const [ width, height ] = WindowSize()

    return (
        <Link to={link} style={{textDecoration: 'none'}} >
            <h1
            style={{
                fontFamily:'franklingothic_book',
                fontSize: width/113 < 10 ? 10 : width/113 > 15 ? 15 : width/113,
                color: !color ? '#000' : color,
                letterSpacing: 0,
                lineHeight: 1.4,
                marginTop: !marginTop ? 0 : marginTop,
                marginBottom: !marginBottom ? 0 : marginBottom,
                marginLeft: !marginLeft ? 0 : marginLeft,
                padding: 0,
                whiteSpace: 'pre-line',
                fontWeight: 'normal',
                cursor: !link && !cursor ? 'default' : 'pointer'
            }}
            >
                {text}
            </h1>
        </Link>
    )
}

export default SmallRegularText;