import React from 'react'
// Components.
import BigBoldText from '../../../Components/Text/BigBoldText'
import MediumBoldText from '../../../Components/Text/MediumBoldText'
import RegularText from '../../../Components/Text/RegularText'
import BoldText from '../../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'

function HomeEight() {
    const [ width, height ] = WindowSize()

    // desktop version
    if(width > Breakpoints.mobile)
        return (
            
            <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                <div style={styles.titleSection}>
                    <BigBoldText text={`Who Art you?`} />
                </div>

                <div style={Object.assign({}, styles.innerContainer, { width: width*0.9 })}>

                    <div style={styles.section} />

                    <div style={styles.section2}>
                        <div style={styles.explore}>
                            <BoldText text={`Explore your type`} marginBottom='1em' />
                        </div>

                        <MediumBoldText text={`Art curious ›`} link='/artcuriousjourney' />
                        <MediumBoldText text={`Artist ›`} link='/artistjourney' />
                        <MediumBoldText text={`Art lover ›`} link='/artloverjourney' />
                        <MediumBoldText text={`Curator ›`} link='/curatorjourney' />

                        <div style={styles.beOne}>
                            <BoldText text={`Be one, two or all!`} marginTop='1em' />
                        </div>
                    </div>

                </div>

            </div>
            
        )
  
    // mobile version
    return (
           
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={Object.assign({}, styles.titleSection, { marginBottom: 30 })}>
                <BigBoldText text={`Who Art you?`} />
            </div>

            <div style={styles.section2Mobile}>

                <div style={{marginBottom: 25}}>
                    <BoldText text={`Explore your type`} />
                </div>
                <MediumBoldText text={`Art curious ›`} link='/artcuriousjourney' />
                <MediumBoldText text={`Artist ›`} link='/artistjourney' />
                <MediumBoldText text={`Art lover ›`} link='/artloverjourney' />
                <MediumBoldText text={`Curator ›`} link='/curatorjourney' />

                <div style={{marginTop: 25}}>
                    <BoldText text={`Be one, two or all!`} />
                </div>
                
            </div>

        </div>
        
    )
}

export default HomeEight;