
export default {

    container: {
        display: 'flex',
        position: 'absolute',
        flexDirection: 'row',
        alignItems: 'center',
        zIndex: 10,
        minWidth: '100%',
        margin: 0,
        bottom: 0,
        paddingTop: '1vh',
        paddingBottom: '1vh',
        backgroundColor: '#fff',
        borderTop: '1px solid #000',
    },

    leftButton: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
        border: '2px solid #000',
        marginBottom: 2.5,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 20,
        paddingRight: 20,
        cursor: 'pointer'

    },

    rightButton: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#000',
        border: '2px solid #000',
        marginBottom: 2.5,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 20,
        paddingRight: 20,
        cursor: 'pointer'
    }

}