import React, { useState } from 'react'
import Vimeo from '@u-wave/react-vimeo'
// Components.
import RegularText from '../../../Components/Text/RegularText'
import BoldText from '../../../Components/Text/BoldText'
// Styling.
import styles from './styles'
import WindowSize from '../../../Components/General/windowsSize'
import Breakpoints from '../../../Components/General/breakpoints'
// Assets.
import GoogleBadge from '../../../Assets/googleBadge.png'
import AppleBadge from '../../../Assets/appleBadge.png'

function HomeFour() {
    const [ width, height ] = WindowSize()
    const [ showVideo, setShowVideo ] = useState(false)

    // desktop version
    if(width > Breakpoints.mobile)
        return (
                
            <div style={Object.assign({}, styles.container, { width: width, height: height })}>

                <div style={styles.innerContainer}>

                    <div style={styles.section}>
                        <RegularText text={`We’re an Artist-centred community. A\nworld-first digital platform where you\ncan enhance your practice, curate an\nexhibition, build your collection or\njust experience stand-out, peer-\nvalidated work.`} marginBottom={30} />

                        <RegularText text={`Hear directly from H(app)y Artists\ntestimonials:`} />
                    
                        <button
                        style={styles.name}
                        onClick={() => setShowVideo('adrian')}
                        >
                            <BoldText text="Adrian Gardner ›" />
                        </button>

                        <button
                        style={styles.name}
                        onClick={() => setShowVideo('sam')}
                        >
                            <BoldText text="Sam Haynes ›" />
                        </button>

                        <button
                        style={styles.name}
                        onClick={() => setShowVideo('thomas')}
                        >
                            <BoldText text="Thomas Garnon ›" />
                        </button>

                        <button
                        style={styles.name}
                        onClick={() => setShowVideo('yulia')}
                        >
                            <BoldText text="Yulia Lebedeva ›" />
                        </button>
                    </div>

                    <div style={styles.section2}>
                        <RegularText text={`And with 0% commission on\npurchases. By subscribing you\nbecome part of a circular economy\nsupporting the entire community.`} />
                        
                        <div style={styles.badgeContainer}>

                            <img
                            onClick={() => window.open('https://apps.apple.com/gb/app/fair-art-fair/id1582203787', '_blank')}
                            style={{height: 60, cursor: 'pointer' }}
                            src={AppleBadge}
                            />

                            <img
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fairartfair', '_blank')}
                            style={{marginLeft: '1em', height: 60, cursor: 'pointer'}}
                            src={GoogleBadge}
                            />

                        </div>
                    </div>

                    {
                        !showVideo ?
                            null
                        :
                            <button
                            style={styles.videoContainer}
                            onClick={() => setShowVideo(false)}
                            >

                                <Vimeo
                                video={showVideo === 'adrian' ? "665945192" : showVideo === 'sam' ? "665945326" : showVideo === 'thomas' ? "665945440" : "666009153"}
                                autoplay={true}
                                loop={false}
                                width={500}
                                height={800}
                                muted={false}
                                controls={false}
                                />

                            </button>
                    }

                </div>

            </div>
            
        )
  
    // mobile version
    return (
            
        <div style={Object.assign({}, styles.container, { width: width, height: height, marginLeft: '-5vw' })}>

            <div style={Object.assign({}, styles.innerContainer, {flexDirection: 'column'})}>

                <div style={styles.sectionMobile}>
                    <RegularText
                    text={`We’re an Artist-centred community. A world-first digital platform where you can enhance your practice, curate an exhibition, build your collection or just experience stand-out, peer- validated work.`}
                    marginBottom={30}
                    />

                    <RegularText text="Hear from Artist experiences here:" />
                    
                    <button
                    style={styles.name}
                    onClick={() => setShowVideo('adrian')}
                    >
                        <BoldText text="Adrian Gardner ›" />
                    </button>

                    <button
                    style={styles.name}
                    onClick={() => setShowVideo('sam')}
                    >
                        <BoldText text="Sam Haynes ›" />
                    </button>

                    <button
                    style={styles.name}
                    onClick={() => setShowVideo('thomas')}
                    >
                        <BoldText text="Thomas Garnon ›" />
                    </button>

                    <button
                    style={styles.name}
                    onClick={() => setShowVideo('yulia')}
                    >
                        <BoldText text="Yulia Lebedeva ›" />
                    </button>
                </div>

                <div style={styles.section2Mobile}>
                    <RegularText text={`And with 0% commission on purchases. By subscribing you become part of a circular economy\nsupporting the entire community.`} />
                    
                    <div style={styles.badgeContainer}>

                        <img
                        onClick={() => window.open('https://apps.apple.com/gb/app/fair-art-fair/id1582203787', '_blank')}
                        style={{height: 40, cursor: 'pointer' }}
                        src={AppleBadge}
                        />

                        <img
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.fairartfair', '_blank')}
                        style={{height: 40, cursor: 'pointer', marginLeft: 5}}
                        src={GoogleBadge}
                        />

                    </div>
                </div>

            </div>

{
    !showVideo ?
        null
    :
        <button
        style={styles.videoContainer}
        onClick={() => setShowVideo(false)}
        >

            <Vimeo
            video={showVideo === 'adrian' ? "665945192" : showVideo === 'sam' ? "665945326" : showVideo === 'thomas' ? "665945440" : "665945550"}
            autoplay={true}
            loop={false}
            width={width * 0.8}
            height={height * 0.8}
            muted={false}
            controls={false}
            />

        </button>
}

        </div>
        
    )
}

export default HomeFour;