import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import BoldText from '../Text/BoldText'
// Styling.
import styles from './styles.js'

function SelectTitle() {
    let location = useLocation()

    if(location.pathname.charAt(1) === 'c')
        return(
            // For artist screens
            <BoldText text='Curator experience' link='/8' />
        )

    else if(location.pathname.charAt(4) === 'l')
        return(
            // For art lover screens
            <BoldText text='Art lover experience' link='/8' />
        )

    else if(location.pathname.charAt(4) === 'c' && location.pathname.charAt(5) === 'u')
        return(
            // For art lover screens
            <BoldText text='Art curious experience' link='/8' />
        )

    else if(location.pathname.charAt(6) === 't')
        return(
            // For art lover screens
            <BoldText text='Artist experience' link='/8' />
        )

    return (
        // Clicking the logo brings you to the homepage and resets the screen and page
        <Link to="/" style={styles.textBold}>
            <svg
            viewBox="0 0 423.94 72.3"
            fill={'#000'}
            height={21}
            >
                <path d="M219.11,35.78l20.17,70.82H223.46l-3.61-14.55H200l-3.5,14.55H182.16L203.4,35.78ZM217,79.84,209.87,51,203,79.84Z" transform="translate(-35.89 -35.36)"/>
                <path d="M255,54.26V66.89c1.28-4.46,3.82-13.38,12.32-13.38a8.9,8.9,0,0,1,2.55.32V68.06a13.1,13.1,0,0,0-2.45-.21c-10.72,0-10.72,12.1-10.72,16.13V106.6H242.17V54.26Z" transform="translate(-35.89 -35.36)"/>
                <path d="M291.68,37.69V54.26h9.66V65.72h-9.66V84.94c0,9.87,0,11.15,10.41,10.51V106.6a43.17,43.17,0,0,1-9,1.06c-4.78,0-10.08-1.17-13.06-5.41-2.54-3.72-2.54-9-2.54-15.72V65.72h-5.24V54.26h6l1.17-15.4Z" transform="translate(-35.89 -35.36)"/>
                <path d="M361.12,35.78V48H334.68V66.47h20.38V78.68H334.68V106.6h-15.5V35.78Z" transform="translate(-35.89 -35.36)"/>
                <path d="M360.58,69.44c.43-3,1.28-9.24,8.71-13.49a24.94,24.94,0,0,1,12.42-2.86c5.52,0,11.26,1.38,15.18,5.52,3.19,3.18,3.4,9.13,3.4,15.5V94.92c0,8.92.32,9.87.85,11.68H387.66c-.64-2.13-.75-3.29-.75-8.07-1.91,3.4-5.3,9.13-14.43,9.13-7,0-13.7-4-13.7-14.33,0-14.55,11.36-18.37,27-19.22v-2c0-4.56,0-9.34-5.21-9.34-4.88,0-5.84,3.93-6.26,8.07Zm25.17,12.1c-8.39,1.06-11.89,3.08-11.89,9.88,0,3.71,1.38,6.58,4.88,6.58a5.65,5.65,0,0,0,4.78-2.87c2-3,2.23-6.26,2.23-11Z" transform="translate(-35.89 -35.36)"/>
                <path d="M408.82,49.8V35.36h14.75V49.8Zm14.65,4.46V106.6H408.92V54.26Z" transform="translate(-35.89 -35.36)"/>
                <path d="M445,54.26V66.89c1.27-4.46,3.82-13.38,12.31-13.38a8.85,8.85,0,0,1,2.55.32V68.06a13,13,0,0,0-2.44-.21c-10.72,0-10.72,12.1-10.72,16.13V106.6H432.12V54.26Z" transform="translate(-35.89 -35.36)"/>
                <path d="M77.82,35.78V48H51.39V66.47H71.77V78.68H51.39V106.6H35.89V35.78Z" transform="translate(-35.89 -35.36)"/>
                <path d="M77.29,69.44c.43-3,1.28-9.24,8.71-13.49a24.92,24.92,0,0,1,12.42-2.86c5.52,0,11.25,1.38,15.18,5.52,3.19,3.18,3.4,9.13,3.4,15.5V94.92c0,8.92.32,9.87.85,11.68H104.36c-.63-2.13-.74-3.29-.74-8.07-1.91,3.4-5.31,9.13-14.44,9.13-7,0-13.69-4-13.69-14.33,0-14.55,11.36-18.37,27-19.22v-2c0-4.56,0-9.34-5.2-9.34-4.88,0-5.84,3.93-6.26,8.07Zm25.16,12.1c-8.38,1.06-11.89,3.08-11.89,9.88,0,3.71,1.38,6.58,4.89,6.58,1.8,0,3.39-.85,4.77-2.87,2-3,2.23-6.26,2.23-11Z" transform="translate(-35.89 -35.36)"/>
                <path d="M125.52,49.8V35.36h14.76V49.8Zm14.65,4.46V106.6H125.63V54.26Z" transform="translate(-35.89 -35.36)"/>
                <path d="M161.67,54.26V66.89C163,62.43,165.5,53.51,174,53.51a8.9,8.9,0,0,1,2.55.32V68.06a13.07,13.07,0,0,0-2.44-.21c-10.73,0-10.73,12.1-10.73,16.13V106.6H148.83V54.26Z" transform="translate(-35.89 -35.36)"/>
            </svg>
        </Link>
    )
}

export default SelectTitle;