export default {

    container: {
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    innerContainer : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginLeft: '10vw',
        minHeight : "100vh"
    },
    
    content: {
        display: 'flex',
        flexDirection: 'row',
        width: '70vw',
        paddingLeft: '10vw'
    },
    
    contentMobile: {
        width: '80vw',
        paddingBottom: 60,
    },

    innerContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },

}