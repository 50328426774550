import React from 'react'
import { Link, useLocation } from 'react-router-dom'
// Styling.
import styles from './styles'
import WindowSize from '../General/windowsSize'
import Breakpoints from '../General/breakpoints'
import '../../styles.css'

function ScrollIndicator() {
    let location = useLocation()
    const [ width, height ] = WindowSize()

    const homeScreens = ['/','/2','/3','/4','/5','/6','/7','/8','/9','/10']
    const artCuriousJourneyScreens = ['/artcuriousjourney','/artcuriousjourney/2']
    const artistJourneyScreens = ['/artistjourney','/artistjourney/2','/artistjourney/3','/artistjourney/4','/artistjourney/5','/artistjourney/6','/artistjourney/7','/artistjourney/8','/artistjourney/9']
    const artLoverJourneyScreens = ['/artloverjourney','/artloverjourney/2','/artloverjourney/3','/artloverjourney/4','/artloverjourney/5','/artloverjourney/6','/artloverjourney/7','/artloverjourney/8','/artloverjourney/9']
    const curatorScreens = ['/curatorjourney','/curatorjourney/2','/curatorjourney/3','/curatorjourney/4','/curatorjourney/5','/curatorjourney/6','/curatorjourney/7','/curatorjourney/8']
    const aboutScreens = ['/about','/about/2','/about/3','/about/4','/about/5','/about/6','/about/7','/about/8','/about/9','/about/10','/about/11','/about/12']

    if(location.pathname === '/watchandlearn')
        return null
    if(location.pathname.charAt(1) === 'c')
        return (

            <div style={Object.assign({}, styles.container, {marginRight: width > Breakpoints.mobile ? 30 : 10})}>

                {/* Display the dots needed for the curator screens */}
                {
                    curatorScreens.map((item) => {
                        return (
                            <Link to={item} key={item}>
                                <button style={styles.scrollDot}>
                                    <svg
                                    viewBox="0 0 7 6.505"
                                    fill={location.pathname === item ? '#000' : '#B4B4B4'}
                                    height={'0.4em'}
                                    >
                                        <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                            <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                                        </g>
                                    </svg>
                                </button>
                            </Link>
                        )
                    })
                }

                    {/* Fake extra button to make it clear the user can keep scrolling to homepage. */}
                    <Link to={'/8'}>
                        <button style={styles.scrollDot}>
                            <svg
                            viewBox="0 0 7 6.505"
                            fill={'#B4B4B4'}
                            height={'0.4em'}
                            >
                                <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                    <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                                </g>
                            </svg>
                        </button>
                    </Link>

            </div>
        )

    else if(location.pathname.charAt(2) === 'b')
        return (

            <div style={Object.assign({}, styles.container, {marginRight: width > Breakpoints.mobile ? 30 : 10})}>

                {/* Display the dots needed for the about screens */}
                {
                    aboutScreens.map((item) => {
                        return (
                            <Link to={item} key={item}>
                                <button style={styles.scrollDot}>
                                    <svg
                                    viewBox="0 0 7 6.505"
                                    fill={location.pathname === item ? '#000' : '#B4B4B4'}
                                    height={'0.4em'}
                                    >
                                        <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                            <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                                        </g>
                                    </svg>
                                </button>
                            </Link>
                        )
                    })
                }

            </div>
        )

    else if(location.pathname.charAt(4) === 'l')
        return (

            <div style={Object.assign({}, styles.container, {marginRight: width > Breakpoints.mobile ? 30 : 10})}>

                {/* Display the dots needed for the art lover screens */}
                {
                    artLoverJourneyScreens.map((item) => {
                        return (
                            <Link to={item} key={item}>
                                <button style={styles.scrollDot}>
                                    <svg
                                    viewBox="0 0 7 6.505"
                                    fill={location.pathname === item ? '#000' : '#B4B4B4'}
                                    height={'0.4em'}
                                    >
                                        <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                            <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                                        </g>
                                    </svg>
                                </button>
                            </Link>
                        )
                    })
                }

                {/* Fake extra button to make it clear the user can keep scrolling to homepage. */}
                <Link to={'/8'}>
                    <button style={styles.scrollDot}>
                        <svg
                        viewBox="0 0 7 6.505"
                        fill={'#B4B4B4'}
                        height={'0.4em'}
                        >
                            <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                            </g>
                        </svg>
                    </button>
                </Link>

            </div>
        )

    else if(location.pathname.charAt(4) === 'c')
        return (

            <div style={Object.assign({}, styles.container, {marginRight: width > Breakpoints.mobile ? 30 : 10})}>

                {/* Display the dots needed for the art curious screens */}
                {
                    artCuriousJourneyScreens.map((item) => {
                        return (
                            <Link to={item} key={item}>
                                <button style={styles.scrollDot}>
                                    <svg
                                    viewBox="0 0 7 6.505"
                                    fill={location.pathname === item ? '#000' : '#B4B4B4'}
                                    height={'0.4em'}
                                    >
                                        <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                            <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                                        </g>
                                    </svg>
                                </button>
                            </Link>
                        )
                    })
                }

                {/* Fake extra button to make it clear the user can keep scrolling to homepage. */}
                <Link to={'/8'}>
                    <button style={styles.scrollDot}>
                        <svg
                        viewBox="0 0 7 6.505"
                        fill={'#B4B4B4'}
                        height={'0.4em'}
                        >
                            <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                            </g>
                        </svg>
                    </button>
                </Link>

            </div>
        )

    else if(location.pathname.charAt(6) === 't')
        return (

            <div style={Object.assign({}, styles.container, {marginRight: width > Breakpoints.mobile ? 30 : 10})}>

                {/* Display the dots needed for the artist screens */}
                {
                    artistJourneyScreens.map((item) => {
                        return (
                            <Link to={item} key={item}>
                                <button style={styles.scrollDot}>
                                    <svg
                                    viewBox="0 0 7 6.505"
                                    fill={location.pathname === item ? '#000' : '#B4B4B4'}
                                    height={'0.4em'}
                                    >
                                        <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                            <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                                        </g>
                                    </svg>
                                </button>
                            </Link>
                        )
                    })
                }

                {/* Fake extra button to make it clear the user can keep scrolling to homepage. */}
                <Link to={'/8'}>
                    <button style={styles.scrollDot}>
                        <svg
                        viewBox="0 0 7 6.505"
                        fill={'#B4B4B4'}
                        height={'0.4em'}
                        >
                            <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                            </g>
                        </svg>
                    </button>
                </Link>

            </div>
        )

    else if(
        location.pathname === '/' ||
        location.pathname === '/2' ||
        location.pathname === '/3' ||
        location.pathname === '/4' ||
        location.pathname === '/5' ||
        location.pathname === '/6' ||
        location.pathname === '/7' ||
        location.pathname === '/8' ||
        location.pathname === '/9' ||
        location.pathname === '/10' ||
        location.pathname === '/11' ||
        location.pathname === '/12' ||
        location.pathname === '/13' ||
        location.pathname === '/14' ||
        location.pathname === '/15' ||
        location.pathname === '/16' ||
        location.pathname === '/17' ||
        location.pathname === '/18' ||
        location.pathname === '/19' ||
        location.pathname === '/20' ||
        location.pathname === '/21' ||
        location.pathname === '/22' ||
        location.pathname === '/23' ||
        location.pathname === '/24' ||
        location.pathname === '/25')
        return (

            <div style={Object.assign({}, styles.container, {marginRight: width > Breakpoints.mobile ? 30 : 5})}>

                {/* Display the dots needed for the home screens */}
                {
                    homeScreens.map((item) => {
                        return (
                            <Link to={item} key={item}>
                                <button style={width > Breakpoints.mobile ? styles.scrollDot : styles.scrollDotMobile}>
                                    <svg
                                    viewBox="0 0 7 6.505"
                                    fill={location.pathname === item ? '#000' : '#B4B4B4'}
                                    height={'0.4em'}
                                    >
                                        <g id="Group_10" data-name="Group 10" transform="translate(-1335.991 -359.641)">
                                            <path id="Path_15" data-name="Path 15" d="M1342.991,366.146h-7v-6.505h7Z"/>
                                        </g>
                                    </svg>
                                </button>
                            </Link>
                        )
                        
                    })
                }

            </div>
        )

    return null
}

export default ScrollIndicator;