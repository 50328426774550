import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function Manifesto() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>
                
                <BigBoldText text={`Art You Ready For a New Art World?`} marginBottom='0.5em' />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                    <RegularText text={`The art world is one of the least examined and deconstructed industries in the world and its time for revolution. That’s where Fair Art Fair comes in: a new Arts Council England-supported digital destination for artists, curators, art lovers as well as the art curious. After six years, 40 exhibitions, 66 artist residencies and building a community of 300 artists, Stacie McCormick and the team behind Unit 1 Gallery | Workshop have developed this app and platform to provide exhibitions, fair opportunities to purchase art, invaluable sales and organisational tools, professional support, exposure and networking to artists without taking a penny from their sales.`} />
                    
                    <RegularText text={`\n“I have to be a solo resident here, I have to be a curator here, I have to be a collector here. All the tools that are in the app are tools I would love to have. They come from the protocols and needs we’ve had to create in order to host exhibitions here. We have streamlined the processes we go through as artists, gallerists, curators and collectors and now we want to share that with the rest of the art world.” — Stacie McCormick, Fair Art Fair & Unit 1 Gallery | Workshop Founder.`} />
                
                    <BoldText text={`\nCircular Economy`} />
                    <RegularText text={`\nIn order to revolutionise the art world and rebuild it in a way that is economically and creatively sustainable, we must remove exploitation, and opportunities for it, from the way artists sell their work, which is why the business model of Fair Art Fair is built outside the finances of art sales. Complete access to the tools provided by the app, including unlimited artwork uploads, is accessible to all artists with a monthly subscription fee of £15.00. By joining Fair Art Fair you are supporting yourself and others. This subscription gives you incredibly valuable benefits, tools and access. Importantly, you also become part of and support a wider group that’s united subscriptions provide more opportunities. You get far more than what you give. Portfolio management tools of this kind are expensive, but this isn’t, and, as it grows, Fair Art Fair’s circular economy will allow it to pay grants and more to its community of artists.`} />
                    <RegularText text={`\nMuch like the groundbreaking Artist Support Pledge Instagram campaign created by Matthew Burrows in response to Covid-19 in March 2020, the Fair Art Fair app is dedicated to providing the platform, support and specific tools to allow artists to professionally bring their work to the world directly. Burrows’ concept for a circular economy for independent artists is a radical move that represents the timely need for new business models within the art world, and has reinforced the importance of creating a digital destination where artists and art professionals from anywhere in the world can connect and work together.`} />

                    <BoldText text={`\nAccess For All,\nWithout Exception`} />
                    <RegularText text={`\nThe tools Fair Art Fair will provide to all artists, without exception, will empower and allow them to professionally organise, present and support their practices. For art curators, collectors and newcomers, the platform will provide unbiased access to the works from a community of curated artists with integrity and rigour, who understand and define their own value. Exhibitions are central to the art experience for artists, curators, collectors and art appreciators alike. The Fair Art Fair app will provide users with intimate access to its own exhibitions — exploring both virtual and physical spaces — where art works will be selected from a community of curated artists. Members will be able to examine the works of art on display, learn more about them and communicate with and purchase directly from the artists featured within them.`} />
                    <RegularText text={`\nCombining decades of experience in artistic practice, curation, collection and nurturing, McCormick and her team have developed a platform of opportunity and transparency.Fair Art Fair heralds the beginnings of a brand-new industry ecosystem that supports and enriches all aspects of the global art world by attending to the needs of those who are integral to it.`} />

                </div>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default Manifesto;