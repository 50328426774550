import React from 'react'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function PrivacyPolicy() {
    const [ width, height ] = WindowSize()
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>
            
            <div style={styles.innerContainer}>
                
                <BigBoldText text={`Privacy policy`} />

                <div style={width > Breakpoints.mobile ? styles.content : styles.contentMobile}>

                    <RegularText text={`FairArtFair ("Fair Art Fair",” “We,” “our,” or “Us”) is committed to protecting your personal infor-mation. We also want to maintain the trust and confidence of every one of our audience mem-bers and supporters, as well as each visitor who uses, accesses the FairArtFair website or appli-cation, and other services (all herein referred to as the “Services”). 
                        \nThis privacy policy (“Policy”) gives you detailed information on when and why we collect your per-sonal information, how we use it and how we keep it secure. 
                        \nThis Policy applies to all users of Fair Art Fair, including any visitor, registered user, curator, seller, buyer, prospective buyer. 
                        \nOur Services may contain links to other companies’ websites and services which they offer; these companies may have privacy policies of their own. We recommend that you read the privacy policies of such third parties. We are not responsible for the privacy practices or content of any such third parties’ products or third parties’ services.
                        \nBy providing Us with your personal information or otherwise using our Services, you acknowledge that you have read, understood, and agree to the terms of this Policy.
                        `} />

                    <BoldText text={`\n1. Information We collect`} />
                    <RegularText text={`\nWe collect the following information:`} />
                    <RegularText text={`\n·	Personal information such as your name, gender, date of birth, relationships, occupation, employment, education, and social life details. 
                        \n·	Contact and account information such as your addresses, telephone numbers, email addresses, username, and any other contact and/or account information that you provide Us.
                        \n·	Sensory information such as photos and videos of you and audio recordings of phone calls between you and Us, where permitted by law. 
                        \n·	Publicly available information We receive from social networking websites or other third parties. When you engage Fair Art Fair to provide the Services through various social media networks, We may receive information from the social network including your profile information, profile picture, profile name, user ID associated with your social media account, country, and any other information you permit the social network to share with third parties. Depending on your social media activity and depending on what information you chose to post, We may also receive sensitive information such as physical and mental health details, sexual life, racial or ethnic origin, religion. The data We receive is dependent upon your privacy settings with the social network, and We will not post information about you on third party social media sites without your consent. you should always review, and if necessary, adjust your privacy settings on third-party websites and services before engaging with our Services.
                        \n·	Information We obtain independently from you Social Media. Depending on your settings or the privacy policies for social media and messaging services like Facebook, In-stagram, Vimeo, YouTube or Twitter, you may give Us permission to access information from those accounts or services.Information available publicly. We may include information found in places such as Com-panies House and information that has been published in articles/ newspapers.
                        \n·	Usage Data ‘Usage Data” is data collected automatically when using the Service. Usage Data may include information such as your device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data. When you access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data. We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device.
                        \n·	Other. Other information you provide to Us when you use our Services or otherwise interact with Us.
                        \nBy providing personal information to Fair Art Fair, you explicitly agree that the information you provide is current, accurate and that you will update it if there are any changes. you are responsible for any third-party personal information obtained, published, or shared by you through Fair Art Fair and hereby confirm that you have the third party's consent to provide the personal information to Fair Art Fair.
                        `} />

                    <BoldText text={`\n2. Tracking Technologies and Cookies`} />
                    <RegularText text={`\n“Cookies” are small files that are placed on your computer, mobile device, or any other device by a website, containing the details of your browsing history on that website among its many uses. We use Cookies and similar tracking technologies to track the activity on our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
                        \nYou can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service.
                        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you close your web browser.
                        \nWe use both Session and Persistent Cookies for the purposes set out below;
                        ·	Necessary / Essential Cookies
                        Type: Session Cookies
                        Administered by: Us
                        Purpose: These Cookies are essential to provide you with our Services and to enable you to use some of the features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the Services that you have asked for cannot be provided, and We only use these Cookies to provide you with those Services.
                        ·	Cookies Policy / Notice Acceptance Cookies
                        Type: Persistent Cookies
                        Administered by: Us
                        Purpose: These Cookies identify if users have accepted the use of cookies on our Services.
                        ·	Functionality Cookies
                        Type: Persistent Cookies
                        Administered by: Us
                        \nPurpose: These Cookies allow us to remember choices you make when you use our Services, such as remembering your login details or language preference. The purpose of these Cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use our Services.
                        `} />

                    <BoldText text={`\n3. Why do We collect your information?`} />
                    <RegularText text={`\nFair Art Fair may process your personal information if one of the following applies:
                        \n·	To manage your Account: to manage your registration as a user of the Service. The per-sonal information you provide can give you access to different functionalities of the Ser-vice that are available to you as a registered user.
                        \n·	For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with Us through the Service.
                        \n·	To contact you: To contact you by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or con-tracted services, including the security updates, when necessary or reasonable for their implementation.
                        \n·	To provide you with news, special offers and general information about other goods, ser-vices and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.
                        \n·	To manage your requests: To attend and manage your requests to Us.
                        \n·	you have given your consent for one or more specific purposes. 
                        \n·	Processing is necessary for compliance with a legal obligation to which Fair Art Fair is subject.
                        \n·	Processing is necessary for the purposes of the legitimate interests pursued by Fair Art Fair or by a third party.
                        \n·	To comply with laws and regulations that apply to Us.
                        `} />

                    <BoldText text={`\n4. Who do We share your information with?`} />
                    <RegularText text={`\nWe will never share, sell, rent or trade your personal information to any third parties for marketing purposes without your prior consent. Some of our service providers may have access to your data in order to perform services on our behalf – payment processing is a good example of this. We make sure anyone who provides a service for Us enters into an agreement with Us and meets our standards for data security. They will not use your data for anything other than the clearly defined purpose relating to the service that they are providing.
                        \nWe will reveal certain personal information described in this Policy only if: (i) you request or authorize it; or (ii) the information is provided to help complete a transaction or a payment for you; or (iii) the information is provided to comply with the law, applicable regulations, governmental and quasi-governmental requests, court orders or subpoenas, to enforce our legal notices or other agreements, or to protect our rights, property or safety or the rights, property or safety of our clients or others; or (iv) the disclosure is done as part of a purchase, transfer or sale of services or assets.
                        \nWhen you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If you interact with other users or register through a third-party social media service, your contacts on such third-party social media service may see your name, profile, pictures and description of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you and view your profile.
                        \nWe may also anonymize or aggregate data about you and disclose such anonymized or aggregated information to third parties for promotional or other purposes.
                        \nWe limit the number of our employees who have access to personal information to a select group of specially authorized employees. Access is granted to these employees for the proper performance of their duties on a need-to-know basis.
                        `} />

                    <BoldText text={`\n5. How do We protect your personal information?`} />
                    <RegularText text={`\nWe work very hard to protect your privacy and have taken key steps in protecting it. We have implemented solutions to protect personal information, which consist of a variety of both technical and policy-based solutions which vary depending on the applicable Service provided to you. These solutions may include, but are not limited to, password protection, encryption, firewalls, anti-virus, intrusion detection, anomaly detection and access controls for our employees. `} />

                    <BoldText text={`\n6. How long and where do We keep personal information? `} />
                    <RegularText text={`\nWe must keep personal information for no longer than is necessary for Us to fulfill the purposes for which the personal information was collected unless We are specifically required to keep your personal information longer by applicable law. The retention period of the personal information differs for the various categories. Personal information of our clients is stored in Europe and US for which appropriate security safeguards are in place. If you wish to enquire further about these appropriate safeguards, please contact Us using the details set out below.`} />

                    <BoldText text={`\n7. Your rights under the GDPR and UK GDPR`} />
                    <RegularText text={`\nIf you reside in the EU, UK, or Switzerland, you are entitled to the following rights in respect of the personal information that We hold:
                        \n·	Withdraw your consent at any time. You have the right to withdraw your consent where you have previously given your consent to the processing of your personal information. 
                        \n·	Object to processing of your information. you have the right to object to the processing of your personal information if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section above.
                        \n·	Access your personal information. you have the right to learn if your personal information is being processed by Fair Art Fair, obtain disclosure regarding certain aspects of the processing, and obtain a copy of the information undergoing processing.
                        \n·	Verify and seek rectification. you have the right to verify the accuracy of your personal information and ask for it to be updated or corrected.
                        \n·	Restrict the processing. you have the right, under certain circumstances, to restrict the processing of your personal information. In this case, Fair Art Fair will not process your personal information for any purpose other than storing it.
                        \n·	Have personal information deleted or otherwise removed. you have the right, under certain circumstances, to obtain the erasure of your information from Fair Art Fair.
                        \n·	Receive information and have it transferred to another controller. you have the right to receive your personal information in a structured, commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the personal information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof.
                        \n·	Lodge a complaint. you have the right to bring a claim before the competent data protection authority.
                        `} />

                    <BoldText text={`\n8. How to exercise your rights?`} />
                    <RegularText text={`\nIf you wish to exercise your privacy rights, please contact Us at privacy@fairartfair.art`} />

                    <BoldText text={`\n9. Changes to this Policy`} />
                    <RegularText text={`\nPlease read this Policy in conjunction with any terms and conditions that may apply to the Services that you have signed up for. This Policy may be subject to change from time to time. We will proactively inform you about such change, by providing you a summary of the change through e.g., email together with a link to the new version of this Policy on our websites and applications `} />

                    <BoldText text={`\n10. Contact`} />
                    <RegularText text={`\nIf you have any questions, comments, or complaints regarding this Policy, you can write to Us at the following address Unit 1, 1 Bard Road, London W10 6TP, UK
                        Or contact Us via email: hello@fairartfair.art
                        Fair Art Fair is wholly owned by Unit 1 Gallery|Workshop Ltd, a company registered in England and Wales, with company number 12811420 with its registered office at Unit 1, 1 Bard Road, London W10 6TP, United Kingdom.
                        \nEffective date: 29 April 2021
                        ©Fair Art Fair, All rights reserved. 
                        `} />

                </div>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default PrivacyPolicy;