import React, { useState, useEffect } from 'react'
// Components.
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import SmallBoldText from '../../Components/Text/SmallBoldText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Apis.
import SubscriberCountApi from '../../Apis/subscriberCount'
import SubscriberStatsApi from '../../Apis/subscriberStats'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'
import SmallRegularText from '../../Components/Text/SmallBoldText'

function SubscriberCount() {
    const [ width, height ] = WindowSize()
    const [ sortedArray, setSortedArray ] = useState([])

    const [ SubscriberCount, loading, result ] = SubscriberCountApi()
    const [ SubscriberStats, loadingStats, resultStats ] = SubscriberStatsApi()

    useEffect(() => {
        SubscriberCount()
        SubscriberStats()
    }, [])

    // Because we are called list not search api, we cant use AWS sort direction so we need to handle sorting the array of results
    // ourselves by date.
    useEffect(() => {
        !resultStats || resultStats.items.length === 0 ?
            console.log(null)
        :
            OrderResults()
    }, [resultStats])

    function OrderResults() {
        var sorting_ = resultStats.items
        
        sorting_.sort((a, b) => {
            return b.date_int - a.date_int
        });

        setSortedArray(sorting_)
    }

    function ConvertDateTime(dateInt) {
        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        var date = new Date(dateInt * 1000);
    
        return  date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear()

    }
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>
                
                <SmallBoldText text="Must have an active subscription" marginTop={50} />

                <BoldText text="Paying - IOS" />
                <RegularText text={!result ? "-" : result.data.ios} />

                <BoldText text="Paying - Android" />
                <RegularText text={!result ? "-" : result.data.android} />

                <SmallBoldText text="The total of roles combined below is higher than above platforms combined because users can have multiple roles per single account" marginTop={50} />

                <BoldText text="Paying - Art Lovers" />
                <RegularText text={!result ? "-" : result.data.counts.roles.art_lover} />

                <BoldText text="Paying - Artists" />
                <RegularText text={!result ? "-" : result.data.counts.roles.artist} />

                <BoldText text="Paying - Curators" />
                <RegularText text={!result ? "-" : result.data.counts.roles.curator} />

                <BoldText text="Total users (paying + not + free accounts)" marginTop={50} />
                <RegularText text={!result ? "-" : result.data.counts.all_counts} />

                <BoldText text="Have accounts but not subscribed (exlcudes free accounts)" />
                <RegularText text={!result ? "-" : result.data.counts.roles.no_roles} />

            </div>

            <BoldText text="Historic Stats" />

            <div style={{display: 'flex', flexDirection: 'row', width: '60vw', overflow: 'scroll', marginBottom: '5em'}}>

                {
                    sortedArray.map(item => {
                        return (
                            <div style={styles.item}>

                                <SmallRegularText text={ConvertDateTime(item.date_int)} />
                                <SmallRegularText text={"Total: " + (item.ios+item.android)} />
                                <br/>
                                <SmallRegularText text={"IOS: " + item.ios} />
                                <SmallRegularText text={"Android: " + item.android} />
                                <br/>
                                <SmallRegularText text={"Artists: " + item.artists} />
                                <SmallRegularText text={"Art Lovers: " + item.art_lovers} />
                                <SmallRegularText text={"Curators: " + item.curators} />

                            </div>
                        )
                    })
                }

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default SubscriberCount;