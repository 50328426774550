
export default {

    line: {
        height: 1,
        backgroundColor: '#000',
        width: '97vw',
        marginLeft: '1.5vw',
        marginRight: 0,
        marginBottom: 0,
        marginTop: 0,
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: '5vw',
        marginTop: 30,
        marginBottom: 30,
        backgroundColor: 'transparent'
    },

    section: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 20,
        alignItems: 'flex-start',
        backgroundColor: 'transparent'
    },

    icon: {
        height: 30,
        width: 30
    },

    image: {
        width: 100,
        margin: 0,
        padding: 0,
        objectFit: 'contain'
    },

    image2: {
        width: 150,
        margin: 0,
        padding: 0,
        objectFit: 'contain'
    }

}