// Styling.
import WindowSize from '../../Components/General/windowsSize'

export default {

    container: {
        paddingTop: '10vh',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    innerContainer : {
        display: 'flex',
        flexDirection: 'column',
        minHeight : "100vh"
    },

    input: {
        fontFamily:'franklingothic_demi',
        fontWeight: 'normal',
        fontSize: WindowSize.width/17 < 40 ? 40 : WindowSize.width/17 > 100 ? 100 : WindowSize.width/17,
        color: '#000',
        paddingLeft: '1.9em',
        paddingRight: '1.9em',
        paddingTop: '1.3em',
        paddingBottom: '1.3em',
        border: '2px solid #000',
        marginTop: '2em',
        outline: 'none',
        backgroundColor: '#fff',
        width: '25%'
    },

    denyButton: {
        fontFamily:'franklingothic_demi',
        fontWeight: 'normal',
        fontSize: WindowSize.width/17 < 40 ? 40 : WindowSize.width/17 > 100 ? 100 : WindowSize.width/17,
        color: '#9D9D9D',
        paddingLeft: '1.9em',
        paddingRight: '1.9em',
        paddingTop: '1.3em',
        paddingBottom: '1.3em',
        border: '2px solid #9D9D9D',
        marginTop: '2em',
        outline: 'none',
        backgroundColor: '#fff',
        width: '14.5%',
        cursor: 'pointer'
    },

    proceedButton: {
        fontFamily:'franklingothic_demi',
        fontWeight: 'normal',
        fontSize: WindowSize.width/17 < 40 ? 40 : WindowSize.width/17 > 100 ? 100 : WindowSize.width/17,
        color: '#000',
        paddingLeft: '1.9em',
        paddingRight: '1.9em',
        paddingTop: '1.3em',
        paddingBottom: '1.3em',
        border: '2px solid #000',
        marginTop: '2em',
        outline: 'none',
        backgroundColor: '#fff',
        width: '14.5%',
        cursor: 'pointer'
    }

}