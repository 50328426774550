import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// Components.
import BigBoldText from '../../Components/Text/BigBoldText'
import BoldText from '../../Components/Text/BoldText'
import RegularText from '../../Components/Text/RegularText'
import Footer from '../../Components/Footer'
import FooterMobile from '../../Components/Footer/indexMobile'
// Context.
import AuthContext from '../../Context/authContext'
// Styling.
import styles from './styles'
import WindowSize from '../../Components/General/windowsSize'
import Breakpoints from '../../Components/General/breakpoints'

function LogIn() {
    let history = useHistory()

    const [ width, height ] = WindowSize()
    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    
    const { LogIn, loadingAuth, userId } = useContext(AuthContext)

    useEffect(() => {
        loadingAuth === 'loaded' && userId != null ?
            history.push('/subscribercount')
        :
            console.log(null)
    }, [loadingAuth, userId])
  
    return (
        <div style={Object.assign({}, styles.container, { width: width, height: height })}>

            <div style={styles.innerContainer}>
                
                <BigBoldText text="Log In" marginBottom='0.5em' />

                <input
                style={styles.input}
                placeholder='Email'
                maxLength="100"
                value={email}
                onChange={event => setEmail(event.target.value)}
                />

                <input
                style={styles.input}
                placeholder='Password'
                maxLength="100"
                value={password}
                onChange={event => setPassword(event.target.value)}
                />

                {/* You can only log in with the defined emails below. This is just temporary. */}
                <button
                style={!email || !password ?
                    styles.denyButton
                :
                    email === "nlimited@live.com" || email === "stacie@fairartfair.art" ?
                        styles.proceedButton
                    :
                        styles.denyButton
                }
                onClick={() => loadingAuth === 'loading' ? console.log(null) : email === "nlimited@live.com" || email === "stacie@fairartfair.art" ? LogIn(email, password) : console.log(null)}
                >
                    {loadingAuth === 'loading' ? 'Loading...' : 'Log in'}
                </button>

            </div>

            <div style={{marginLeft: width > Breakpoints.mobile ? '-20vw' : '-5vw'}}>
                {width > Breakpoints.mobile ? <Footer /> : <FooterMobile />}
            </div>

        </div>
    )
}

export default LogIn;